import { CopyOutlined } from "@ant-design/icons";
import { BaseKey } from "@refinedev/core";
import { useDuplicateAbTestConfig } from "@src/hooks/ab-testing/use-duplicate-ab-test-config";
import { Button, Popconfirm, Tooltip } from "antd";
import { FormInstance } from "antd/lib/form";

type DuplicateButtonProps = {
  id?: string | BaseKey;
  form: FormInstance<any>;
  disabled?: boolean;
  message?: string;
};
export const DuplicateButtonAbTest: React.FC<DuplicateButtonProps> = ({ id, message = "Duplicate", disabled }) => {
  const { refetch } = useDuplicateAbTestConfig({
    type: "explore-listing",
  });

  const onDuplicate = async () => {
    refetch(id);
  };
  return (
    <Popconfirm title="Are you sure duplicate this exploration?" onConfirm={() => onDuplicate()}>
      <Tooltip title={message}>
        <Button icon={<CopyOutlined />} size="small" disabled={disabled} />
      </Tooltip>
    </Popconfirm>
  );
};
