import { css } from "@emotion/react";
import { useApiUrl, useCustom } from "@refinedev/core";
import { MEASURE_RATIO_FIREBASE, MEASURE_RETENTION_BY_EVENT_NAME } from "@src/constant/ab-testing";
import { SupportedMeasureType, SupportedModelAlias } from "@src/constant/ab-testing/ab-testing-exploration.enum";
import { useMetricPerformanceContext } from "@src/contexts/ab-testing/metric-performance-context";
import { toTitle } from "@src/helpers/text-helper";
import { useGetGeneralField } from "@src/hooks/ab-testing/use-get-general-field";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { IMemberRationOptions } from "@src/types/ab-testing-exploration";
import { Form, Select, Typography } from "antd";
import React from "react";
import { v4 as uuidv4 } from "uuid";

export const MeasureLabelCommon: React.FC<{ record: any; onClick?: () => void }> = ({ record, onClick }) => {
  const isFirebaseRatio = record?.name === MEASURE_RATIO_FIREBASE;
  const isCustomRetention = record?.name === MEASURE_RETENTION_BY_EVENT_NAME;

  const measureLabel = isFirebaseRatio
    ? "Ratio event count"
    : isCustomRetention
    ? "Retention (By Selected Event)"
    : record?.title || toTitle(record.name);
  const measureType = toTitle(
    isFirebaseRatio ? SupportedMeasureType.RATIO : record.options?.measure_type || SupportedMeasureType.MEAN,
  );
  return (
    <div className="flex justify-between" onClick={onClick}>
      <div>{measureLabel}</div>
      <div>
        <Typography.Text type="secondary">{measureType}</Typography.Text>
      </div>
    </div>
  );
};

export const MeasureLabelSpecific: React.FC<{ targetKey: string }> = ({ targetKey }) => {
  const measureType = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_MEASURE_TYPE]);
  const measureValue = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_MEASURE_VALUE]);
  const targetModal = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_TARGET_MODEL]);
  const measureName = Form.useWatch([targetKey, ChartFormFieldsEnum.METRIC_MEASURE]);

  // if (!!measureValue && measureType === SupportedMeasureType.RATIO && targetModal === "firebase") {
  if (
    measureName === MEASURE_RATIO_FIREBASE &&
    measureType === SupportedMeasureType.RATIO &&
    targetModal === SupportedModelAlias.FIREBASE
  ) {
    return <MeasureLabelRatio targetKey={targetKey} />;
  } else if (
    measureName === MEASURE_RETENTION_BY_EVENT_NAME &&
    measureType === SupportedMeasureType.PROPORTION &&
    targetModal === SupportedModelAlias.FIREBASE_ENGAGEMENT
  ) {
    return <MeasureRetentionLabelRatio targetKey={targetKey} />;
  }

  return null;
};

const MeasureLabelRatio: React.FC<{ targetKey: string }> = ({ targetKey }) => {
  const { form, handleUpdateMetricTabItems } = useMetricPerformanceContext();

  const { productCode } = useGetGeneralField({});
  const apiUrl = useApiUrl("nestjsx");
  const { data, isLoading } = useCustom({
    method: "get",
    dataProviderName: "nestjsx",
    url: `${apiUrl}/products/event-name/${productCode}`,
    queryOptions: {
      enabled: Boolean(productCode),
    },
    config: {
      headers: {
        "Product-Code": productCode,
      },
    },
  });
  const options = data?.data?.map((item: any) => ({ label: item?.event_name, value: item.event_name })) ?? [];
  const formatMeasureValue = ({
    numerator,
    denominator,
    template,
  }: {
    numerator: string;
    denominator: string;
    template?: string;
  }) => {
    if (template === undefined) return "";
    return template?.replace("{event_name1}", numerator)?.replace("{event_name2}", denominator);
  };

  const handleUpdateMeasureValue = ({ numerator, denominator }: { numerator: string; denominator: string }) => {
    const parsedMeasureOption = JSON.parse(
      form?.getFieldValue([targetKey, ChartFormFieldsEnum.METRIC_MEASURE_OPTIONS]) ?? "{}",
    );

    const newMetricMeasureOption: IMemberRationOptions = {
      ...parsedMeasureOption,
      measure_definition: {
        denominator: `${denominator}_event_count`,
        numerator: `${numerator}_event_count`,
      },
    };

    form.setFieldValue(
      [targetKey, ChartFormFieldsEnum.METRIC_MEASURE_VALUE],
      formatMeasureValue({ numerator, denominator, template: parsedMeasureOption?.name }),
    );

    form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_MEASURE_OPTIONS], JSON.stringify(newMetricMeasureOption));
    const metricFilter = form.getFieldValue([targetKey, ChartFormFieldsEnum.METRIC_FILTER]);
    const filterRule = metricFilter?.rules ?? [];
    const eventNameRatioValue = [numerator, denominator].filter((v) => !!v);
    const newMetricFilter = {
      ...metricFilter,
      rules: filterRule?.some((_r: any) => _r.field === "event_name")
        ? filterRule?.map((_r: any) => {
            if (_r.field === "event_name") {
              return { ..._r, value: eventNameRatioValue, disabled: true };
            }
            return _r;
          })
        : [
            ...filterRule,
            { field: "event_name", operator: "=", value: eventNameRatioValue, disabled: true, id: uuidv4() },
          ],
    };

    form.setFieldValue([targetKey, ChartFormFieldsEnum.METRIC_FILTER], newMetricFilter);
    handleUpdateMetricTabItems(targetKey, [
      ChartFormFieldsEnum.METRIC_MEASURE_VALUE,
      ChartFormFieldsEnum.METRIC_MEASURE_OPTIONS,
      ChartFormFieldsEnum.METRIC_FILTER,
    ]);
  };
  const getFirstValueOfEventRation = (value: string | string[]) => {
    if (typeof value === "string") return value ?? null;
    return value?.[0] ?? "";
  };
  return (
    <div className="flex space-x-4">
      <RatioSelectBase
        form={{
          label: "Numerator",
          name: [targetKey, ChartFormFieldsEnum.NUMERATOR],
        }}
        options={options}
        onChange={(e) => {
          setTimeout(() => {
            handleUpdateMeasureValue({
              numerator: getFirstValueOfEventRation(e),
              denominator: getFirstValueOfEventRation(form.getFieldValue([targetKey, ChartFormFieldsEnum.DENOMINATOR])),
            });
          }, 0);
        }}
        isLoading={isLoading}
      />
      <RatioSelectBase
        form={{
          label: "Denominator",
          name: [targetKey, ChartFormFieldsEnum.DENOMINATOR],
        }}
        options={options}
        onChange={(e) => {
          setTimeout(() => {
            handleUpdateMeasureValue({
              numerator: getFirstValueOfEventRation(form.getFieldValue([targetKey, ChartFormFieldsEnum.NUMERATOR])),
              denominator: getFirstValueOfEventRation(e),
            });
          }, 0);
        }}
        isLoading={isLoading}
      />
    </div>
  );
};

const MeasureRetentionLabelRatio: React.FC<{ targetKey: string }> = ({ targetKey }) => {
  const { form, handleUpdateMetricTabItems } = useMetricPerformanceContext();

  const { productCode } = useGetGeneralField({});
  const apiUrl = useApiUrl("nestjsx");
  const { data, isLoading } = useCustom({
    method: "get",
    dataProviderName: "nestjsx",
    url: `${apiUrl}/products/event-name/${productCode}`,
    queryOptions: {
      enabled: Boolean(productCode),
    },
    config: {
      headers: {
        "Product-Code": productCode,
      },
    },
  });
  const options =
    data?.data
      ?.filter((item: { event_name: string }) => {
        return (
          !!item?.event_name &&
          (["user_engagement", "screen_view", "first_open", "app_exception"].includes(item.event_name) ||
            item.event_name.startsWith("me_") ||
            item.event_name.startsWith("song_"))
        );
      })
      .map((item: any) => ({ label: item?.event_name, value: item.event_name })) ?? [];

  const formatMeasureValue = ({ eventName, template }: { eventName: string; template?: string }) => {
    if (template === undefined) return "";
    return template?.replace("{event_name}", eventName);
  };

  const handleUpdateMeasureValue = ({ eventName }: { eventName: string }) => {
    const measureName = form?.getFieldValue([targetKey, ChartFormFieldsEnum.METRIC_MEASURE]);

    form.setFieldValue(
      [targetKey, ChartFormFieldsEnum.METRIC_MEASURE_VALUE],
      formatMeasureValue({ eventName, template: measureName }),
    );

    handleUpdateMetricTabItems(targetKey, [ChartFormFieldsEnum.METRIC_MEASURE_VALUE]);
  };

  const getFirstValueOfEventRation = (value: string | string[]) => {
    if (typeof value === "string") return value ?? null;
    return value?.[0] ?? "";
  };
  return (
    <div className="flex space-x-4">
      <RatioSelectBase
        form={{
          label: "Event name",
          name: [targetKey, ChartFormFieldsEnum.EVENT_NAME],
        }}
        options={options}
        onChange={(e) => {
          setTimeout(() => {
            handleUpdateMeasureValue({
              eventName: getFirstValueOfEventRation(e),
            });
          }, 0);
        }}
        isLoading={isLoading}
      />
    </div>
  );
};

const RatioSelectBase: React.FC<{
  form: {
    label: string;
    name: string[];
  };
  options: any[];
  onChange: (e?: any) => void;
  isLoading: boolean;
}> = ({ form, onChange, options, isLoading }) => {
  return (
    <div
      css={css`
        .ant-form-item {
          margin-bottom: 0 !important;
        }
      `}
    >
      <Form.Item label={form.label} required />
      <Form.Item
        style={{ width: 230 }}
        name={form.name}
        rules={[{ required: true, message: `Please input ${form.label.toLowerCase()}` }]}
      >
        <Select
          mode="tags"
          maxCount={1}
          placeholder="Denominator"
          options={options}
          loading={isLoading}
          onChange={onChange}
        />
      </Form.Item>
    </div>
  );
};
