import React from "react";
import { UserCountItemProps } from ".";
import ReactECharts from "echarts-for-react";
import { EChartWrapper } from "../../layout/echart-wrapper";
import { SupportedModelAlias } from "@src/constant/ab-testing/ab-testing-exploration.enum";
import { IQuery } from "@src/types/ab-testing-exploration";
import { useGetGeneralField } from "@src/hooks/ab-testing/use-get-general-field";
import { ChartAction, ChartActionLayout } from "../action";
import { ChartDataTypeEnum, useChartLayout } from "@src/hooks/ab-testing/use-chart-layout";
import { RawDataTable } from "./raw-data-table";
import { useGroupingLoadAndGetRawData } from "@src/hooks/ab-testing/use-grouping-load-and-get-raw-data";
import { ChartLayoutWithUserPercent } from "../../layout/chart-layout-with-user-percent";
import { formatNumber } from "@src/util/number-format";

const UserCountByOnboardingDate: React.FC<UserCountItemProps> = ({ form, chartKey }) => {
  const { chartDataType, setChartDataType } = useChartLayout();

  const valuesOfGeneralField = useGetGeneralField({ form });
  const { explorationFilters, getExpAliasById, explorationOptions } = valuesOfGeneralField;

  const jsonQuery: IQuery = {
    dimensions: ["exp_group", "first_signal_date"],
    measures: ["user_count"],
    filters: explorationFilters,
    limit: 10000,
    order: [
      ["first_signal_date", "asc"],
      ["exp_group", "asc"],
    ],
  };

  const modelAlias = SupportedModelAlias.USER_INFO;
  const {
    loadResult: result,
    tableProps,
    isLoading,
  } = useGroupingLoadAndGetRawData({
    chartKey: chartKey,
    jsonQuery: jsonQuery,
    modelAlias: modelAlias,
    queryOptions: explorationOptions,
    groupRawDataBy: {
      key: "first_signal_date",
      label: "Onboarding Date",
    },
  });

  const groupedResult = result ? Object.groupBy(result, ({ exp_group }) => getExpAliasById(exp_group)) : {};

  const dataSetMapping: any = Object.keys(groupedResult).map((seriesName) => {
    if (chartDataType === ChartDataTypeEnum.USER_PERCENT) {
      const total = groupedResult[seriesName]?.reduce((acc, item) => acc + item.user_count, 0) ?? 0;
      return {
        source: groupedResult[seriesName]?.map((item) => {
          return {
            ...item,
            user_count: total > 0 ? ((item.user_count * 100) / total).toFixed(2) : 0,
          };
        }),
      };
    }

    return {
      source: groupedResult[seriesName],
    };
  });

  const seriesMapping: echarts.EChartsOption["series"] = Object.keys(groupedResult).map((seriesName, idx) => {
    return {
      type: "line",
      datasetIndex: idx,
      name: seriesName,
      encode: { x: "first_signal_date", y: "user_count" },
    };
  });

  const option: echarts.EChartsOption = {
    animationDuration: 1000,
    tooltip: {
      trigger: "axis",
      formatter: (params: any) => {
        let stringTemplate = `${params[0]?.axisValueLabel?.split(" ")?.[0]} <br />`;
        params.forEach((item: any) => {
          stringTemplate += `${item?.marker} ${item?.seriesName}: ${
            chartDataType === ChartDataTypeEnum.USER_COUNT
              ? formatNumber(item?.data?.user_count)
              : item?.data?.user_count + "%"
          } <br />`;
        });

        return stringTemplate;
      },
    },
    grid: {
      bottom: 100,
      top: 10,
      right: 100,
    },
    dataZoom: [
      {
        type: "slider",
      },
      {
        type: "inside",
        zoomOnMouseWheel: "shift",
      },
    ],
    dataset: dataSetMapping,
    xAxis: { type: "time" },
    yAxis: {
      axisLabel: {
        formatter: (value: string) => {
          return chartDataType === ChartDataTypeEnum.USER_COUNT ? value : value + "%";
        },
      },
    },
    series: seriesMapping,
    legend: {
      orient: "vertical",
      align: "right",
      right: 10,
      bottom: 200,
    },
  };

  return (
    <ChartLayoutWithUserPercent
      chartKey={chartKey}
      chartDataType={chartDataType}
      setChartDataType={setChartDataType}
      loading={!!isLoading}
      moreActions={
        <ChartActionLayout>
          <ChartAction tabKey={chartKey} tabName="Onboarding Date" form={form} />
        </ChartActionLayout>
      }
      renderRaw={<RawDataTable {...tableProps} />}
      renderChart={
        <EChartWrapper isEmpty={result === undefined || (result && result.length === 0)}>
          <ReactECharts key={JSON.stringify(dataSetMapping)} option={option} style={{ height: "100%" }} />
        </EChartWrapper>
      }
    />
  );
};
export const UserCountByOnboardingDateMemo = React.memo(UserCountByOnboardingDate);
