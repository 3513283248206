import { AimOutlined, ApartmentOutlined, CompassOutlined, ExperimentOutlined, FundOutlined } from "@ant-design/icons";
import { useNavigation } from "@refinedev/core";
import { Button, Card, Modal, ModalProps, Tag } from "antd";
interface ExplorationItemCreateProps {
  title: string;
  enabled?: boolean;
  icon: JSX.Element;
  url?: string;
  type: string;
}
export const PopupNewExplore: React.FC<{ modalProps: ModalProps }> = ({ modalProps: { visible, ..._modalProps } }) => {
  const { push } = useNavigation();
  const exploreOpts: ExplorationItemCreateProps[] = [
    {
      title: "Firebase exploration",
      enabled: true,
      icon: <AimOutlined className="text-[80px] block self-center  mb-4" />,
      url: "/explorations/firebase/create",
      type: "firebase_exploration",
    },
    {
      title: "AB Test exploration",
      enabled: true,
      icon: <ExperimentOutlined className="text-[80px] block self-center  mb-4" />,
      url: "/explorations/ab-test/create",
      type: "ab-test",
    },
    // {
    //   title: "Multi-step query",
    //   icon: <ApartmentOutlined className="text-[80px] block self-center  mb-4" />,
    //   type: "multi_step",
    // },
    // {
    //   title: "Funnel",
    //   icon: <FundOutlined className="text-[80px] block self-center  mb-4" />,
    //   type: "funnel",
    // },
    // {
    //   title: "Cohort",
    //   icon: <CompassOutlined className="text-[80px] block self-center  mb-4" />,
    //   type: "cohort",
    // },
  ];

  return (
    <Modal {..._modalProps} title={"New explore"} centered okButtonProps={{ hidden: true }}>
      <div className="flex flex-wrap -mx-4">
        {exploreOpts.map((item) => {
          return (
            <Button
              key={item.title}
              className={`px-1 w-1/2 mb-2 h-auto ${item.enabled ? "opacity-100" : "opacity-55"}`}
              type="text"
              disabled={!item.enabled}
              onClick={() => {
                item.url && push(item.url);
              }}
            >
              <Card key={item.title} hoverable className="flex items-center justify-center">
                {item.icon}
                <Card.Meta title={item.title} className="text-center" />
              </Card>
              {!item.enabled && (
                <Tag color="green" className="absolute top-2 right-0">
                  Coming soon
                </Tag>
              )}
            </Button>
          );
        })}
      </div>
    </Modal>
  );
};
