import { Form, Input } from "antd";
import { config } from "@src/config";
import { CubeProvider } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";

import { ABFilterFormContent } from "./form";
import { FormInstance } from "antd/lib/form";
import { ChartFormFieldsEnum, FilterFormFieldsEnum } from "../../enum";
import React from "react";
import { ChartFormInstanceType, FilterFormInstanceType } from "@src/contexts/ab-testing/ab-context";
import { FORM_NAME } from "@src/constant/ab-testing/user-count";
import { FilterExplorationLayout } from "../layout/filter-exploration-layout";
import { convertDateRangeToString } from "@src/util/time-form";
import { COHORT_DATE } from "../../contanst";
import { shared } from "@src/util/shared";
import { getABTestLocation } from "@src/util/ab-testing/get-ab-test-location";
import { Portal } from "@src/components/portal";
import { ABPortalContainerId } from "@src/constant/ab-testing/portal-container-id.enum";
import { TitleABConfig } from "../title-ab-config";
import { ABSaveConfigComponent } from "../ab-save-config-component";

type FilterExplorationProps = {
  form: FormInstance<FilterFormInstanceType>;
  chartFormInstance: FormInstance<ChartFormInstanceType>;
};
export const FilterExploration: React.FC<FilterExplorationProps> = ({ form, chartFormInstance }) => {
  const { isCreateMode } = getABTestLocation();

  const productCodeWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_CODE, form);
  const CUBE_TOKEN = `Bearer ${shared.get("token")}`;
  const cubejsApi = cubejs(CUBE_TOKEN, {
    apiUrl: config.NESTJS_URL + "/api/proxy/cube",
    headers: {
      "product-code": productCodeWatch,
    },
  });

  const handleFinish = (values: any) => {
    const _values: Record<string, any> = {};

    [
      FilterFormFieldsEnum.PRODUCT_CODE,
      FilterFormFieldsEnum.ABA_ID,
      FilterFormFieldsEnum.AMA_APP_IDS,
      FilterFormFieldsEnum.PLATFORM,
      FilterFormFieldsEnum.VARIANTS,
      FilterFormFieldsEnum.VARIANTS_OPTIONS,
      FilterFormFieldsEnum.COHORT_DATE,
      FilterFormFieldsEnum.MAX_DAY_DIFF,
      FilterFormFieldsEnum.EXTRA_INSTALL_DATE,
      FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE,
      FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED,
    ].forEach((field) => {
      if ([FilterFormFieldsEnum.EXTRA_INSTALL_DATE, FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE].includes(field)) {
        const _parseValue = JSON.parse(values[FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED] ?? "{}");
        _values[field] = _parseValue?.[field] ?? convertDateRangeToString(_parseValue?.[field]);
      } else {
        _values[field] = values[field];
      }
    });
    chartFormInstance.setFieldValue(ChartFormFieldsEnum.FILTER_FIELDS, JSON.stringify(_values));
  };

  const getInitValue = () => {
    if (!isCreateMode) return {};

    const initFilterOutUserField = {
      [FilterFormFieldsEnum.INVALID_COUNTRIES]: true,
      [FilterFormFieldsEnum.MULTI_USER_PSEUDO_ID]: true,
      [FilterFormFieldsEnum.USERS_JOIN_MULTIPLE_GROUPS]: true,
      [FilterFormFieldsEnum.LATE_ONBOARDING_DATE]: true,
      // [FilterFormFieldsEnum.LATE_ONBOARDING_DATE]: false, // true as default later
    };
    return {
      [FilterFormFieldsEnum.COHORT_DATE]: COHORT_DATE.INSTALL_DATE,
      [FilterFormFieldsEnum.MAX_DAY_DIFF]: config.DEFAULT_MAX_DAY_DIFF,

      [FilterFormFieldsEnum.IS_ABA_ID_CHANGED]: false,
      ...initFilterOutUserField,
    };
  };

  return (
    <CubeProvider cubeApi={cubejsApi}>
      <Form
        name={FORM_NAME.FILTER_FORM}
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        initialValues={getInitValue()}
      >
        <Portal containerId={ABPortalContainerId.AB_TITLE_COMPONENT}>
          <TitleABConfig />
        </Portal>
        <Portal containerId={ABPortalContainerId.AB_SAVE_COMPONENT}>
          <ABSaveConfigComponent />
        </Portal>

        <FilterExplorationLayout form={form} isLoadingGenerateToken={false}>
          <ABFilterFormContent form={form} />
        </FilterExplorationLayout>
        <Form.Item name={FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED} hidden>
          <Input />
        </Form.Item>
      </Form>
    </CubeProvider>
  );
};
