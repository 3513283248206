import { CloseOutlined } from "@ant-design/icons";
import { FilterFormInstanceType } from "@src/contexts/ab-testing/ab-context";
import { ALL_EXTRA_FIELDS_WITH_TYPE_BOOLEAN } from "@src/pages/ab-test-explore/contanst/filter-fields";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import {
  FILTER_OUT_USER_NAME,
  getGroupingExtraField,
} from "@src/pages/ab-test-explore/helper/get-grouping-extra-field";
import { sliceFieldValue } from "@src/pages/ab-test-explore/helper/slice-field-value";
import { trackEventPayload } from "@src/util/track-event-payload";
import { Form, Tooltip } from "antd";
import { FormInstance } from "antd/lib/form";
import { useTracking } from "react-tracking";

export const ChipsListing: React.FC<{ form: FormInstance<FilterFormInstanceType> }> = ({ form }) => {
  const { trackEvent } = useTracking();

  const extraFilterValuesConfirmedWatch =
    Form.useWatch(FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED, form) ?? "{}";
  const extraFilterValuesConfirmedWatchDebounce = JSON.parse(extraFilterValuesConfirmedWatch);
  const chipsInfo = getGroupingExtraField(extraFilterValuesConfirmedWatchDebounce);

  return (
    <div className="flex flex-wrap mb-4">
      {chipsInfo?.map((chip) => {
        const { result, remaining } = sliceFieldValue({
          value: chip.value,
          numSlice: 3,
          joinSymbol: chip.relation === "between" ? " & " : ", ",
        });
        const handleDelete = (e: any) => {
          e.stopPropagation();
          const currExtraFieldConfirmed = JSON.parse(
            form.getFieldValue(FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED) || "{}",
          );
          if (chip.name === FILTER_OUT_USER_NAME) {
            ALL_EXTRA_FIELDS_WITH_TYPE_BOOLEAN.forEach((field) => {
              form.setFieldValue(field, undefined);
              currExtraFieldConfirmed[field] = undefined;
            });
          } else {
            form.setFieldValue(chip.name, undefined);
            currExtraFieldConfirmed[chip.name] = undefined;
          }
          form.setFieldValue(
            FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED,
            JSON.stringify(currExtraFieldConfirmed),
          );
          trackEvent(trackEventPayload.extraFieldFilter("field_update", currExtraFieldConfirmed));
        };
        return (
          <Tooltip
            key={chip.label + chip.value}
            title={
              <div className="max-h-[300px] overflow-y-scroll text-[12px]">
                {chip.value?.map((v) => {
                  return <div key={v}>{v}</div>;
                })}
              </div>
            }
          >
            <div
              className="group px-2 py-[1px] m-1 border-[1px] border-solid border-blue-200 rounded-[10px] bg-blue-100  flex overflow-hidden items-center hover:bg-blue-400 hover:text-white duration-200 cursor-zoom-in	 text-[12px]"
              onClick={() => {
                form.setFieldValue(FilterFormFieldsEnum.IS_OPEN_MODAL, true);
                trackEvent(trackEventPayload.extraFieldFilter("modal_open", {}));
              }}
            >
              <span>{chip.label}</span>
              <span className="px-1 font-medium">{chip.relation}</span>
              <span className="text-blue-500 group-hover:text-white">
                {result}
                {remaining > 0 ? `, +${remaining}` : ""}
              </span>
              <CloseOutlined className="ml-2 hover:text-[12px] " onClick={(e) => handleDelete(e)} />
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
};
