import React from "react";
import { Button, Result } from "antd";
import { useLogout } from "@refinedev/core";

const Error403: React.FC<{ message?: string; onBack?: () => void }> = ({ message, onBack }) => {
  const { mutate: logout } = useLogout();

  return (
    <div className="flex justify-center items-center h-full">
      <Result
        status="403"
        title="403"
        subTitle={message || "Sorry, you are not authorized to access this page."}
        extra={
          <Button
            type="primary"
            onClick={() => {
              if (onBack) {
                onBack();
              } else {
                logout();
              }
            }}
          >
            Go back
          </Button>
        }
      />
    </div>
  );
};

export default Error403;
