import { Edit } from "@refinedev/antd";
import { useNavigation, useOne, useParsed } from "@refinedev/core";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import {
  parsedChartFormValueFromApi,
  parsedFilterFormValueFromApi,
} from "@src/util/ab-testing/handle-init-value-for-ab-test";
import { FilterFormFieldsEnum } from "./enum";
import { ABPortalContainerId } from "@src/constant/ab-testing/portal-container-id.enum";
import { getUserInfo } from "@src/util/get-user-info";
import React from "react";
import { PAGE_URL } from "@src/config";
import { useUpdateCacheInitAbConfig } from "@src/hooks/ab-testing/use-update-cache-init-ab-config";
import { css } from "@emotion/react";
import { shared } from "@src/util/shared";
import { useCanEditAbTestExplore } from "@src/hooks/ab-testing/can/use-can-edit-ab-test";
import { CUSTOM_EVENT_NAME } from "@src/constant/ab-testing/custom-event-name.enum";
import { Error403 } from "../error/403";
import { useMe } from "@src/hooks/use-me";

export const INIT_CONFIG_SHARED_KEY = "init_config_shared_key";
export const DEFAULT_TITLE = "AB test exploration";
export const ABTestExploreEdit: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { push } = useNavigation();
  const { isSuperAdmin } = useMe();

  const [dontHavePermission, setDontHavePermission] = React.useState(false);
  const userInfo = getUserInfo();
  const { filterForm, chartForm } = useABContext();
  const { cacheAbConfig } = useUpdateCacheInitAbConfig();
  const params = useParsed();
  const id = params.id;
  const { canEdit } = useCanEditAbTestExplore();
  const handleSetInitialValue = (data: any) => {
    const config = data.config;
    const parsedGeneralConfig = parsedFilterFormValueFromApi(config?.generalConfig);

    const parsedChartConfig = parsedChartFormValueFromApi(config?.chartConfig);

    filterForm.setFieldsValue({
      ...parsedGeneralConfig,
      [FilterFormFieldsEnum.IS_FIRST_APPLIED]: false,
      [FilterFormFieldsEnum.TITLE]: data.title ?? DEFAULT_TITLE,
      [FilterFormFieldsEnum.DESCRIPTION]: data.description,
    });
    chartForm.setFieldsValue(parsedChartConfig);
    cacheAbConfig(data);
  };
  const { isLoading, isFetching } = useOne({
    dataProviderName: "nestjsx",
    resource: "explorations",
    id,
    queryOptions: {
      onSuccess: (data) => {
        if (!data.data) return;
        if (!isSuperAdmin && data?.data?.owner !== userInfo?.email && !data?.data?.is_public) {
          setDontHavePermission(true);
        }

        handleSetInitialValue(data?.data);
      },
      onError: (err) => {
        if (err?.response?.status === 403) {
          setDontHavePermission(true);
        }
      },
      onSettled: () => {
        window.dispatchEvent(
          new CustomEvent(CUSTOM_EVENT_NAME.GET_AB_TEST_LOADING, {
            detail: {
              loading: false,
            },
          }),
        );
      },

      queryKey: ["explorations", "edit", id],
    },
  });

  React.useEffect(() => {
    if (!filterForm || !chartForm) return;

    return () => {
      shared.delete(INIT_CONFIG_SHARED_KEY);
    };
  }, []);

  if (dontHavePermission) {
    return (
      <Error403
        message="You don't have permission to view this page. Please contact our admin for support
"
        onBack={() => {
          push(PAGE_URL.EXPLORATION.ROOT);
        }}
      />
    );
  }
  const loading = isLoading || isFetching;

  return (
    <div
      css={
        canEdit
          ? css`
              .ant-page-header-content {
                padding: 0;
              }
            `
          : css`
              .ant-page-header-content {
                padding: 0;
              }
              .ab-title-group {
                span {
                  background: rgba(0, 0, 0, 0.04) !important;
                  color: rgba(0, 0, 0, 0.25) !important;
                  cursor: not-allowed !important;
                  pointer-events: none !important;
                  &:hover {
                    border-color: #d9d9d9 !important;
                  }
                }
              }
              .ab-save-group {
                button {
                  background: rgba(0, 0, 0, 0.04) !important;
                  color: rgba(0, 0, 0, 0.25) !important;
                  cursor: not-allowed !important;
                  pointer-events: none !important;
                  &:hover {
                    border-color: #d9d9d9 !important;
                  }
                }
              }
            `
      }
    >
      <Edit
        isLoading={loading}
        title={<div id={ABPortalContainerId.AB_TITLE_COMPONENT} />}
        headerButtons={<div id={ABPortalContainerId.AB_SAVE_COMPONENT} />}
        contentProps={{
          className: "bg-transparent shadow-none card-custom",
        }}
        breadcrumb={null}
        footerButtonProps={{
          hidden: true,
        }}
        wrapperProps={{
          className: "base-custom-container",
        }}
      >
        {children}
      </Edit>
    </div>
  );
};
