import { FORM_NAME } from "@src/constant/ab-testing/user-count";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { INIT_CONFIG_SHARED_KEY } from "@src/pages/ab-test-explore/edit";
import { ChartFormFieldsEnum, FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { shared } from "@src/util/shared";
import { Form } from "antd";

export const useAbSaveConfig = () => {
  const { getCurrentConfig, filterForm, chartForm } = useABContext();
  // Trigger watch to check if the form is changing
  const filterFormWatch = Form.useWatch([], filterForm);
  Form.useWatch([], chartForm);

  const { [FORM_NAME.FILTER_FORM]: currentFilterForm, [FORM_NAME.CHART_FORM]: currentChartForm } = getCurrentConfig();

  const isTitleOrDescriptionChanging =
    JSON.stringify({
      [FilterFormFieldsEnum.TITLE]: shared.get(INIT_CONFIG_SHARED_KEY)?.[FilterFormFieldsEnum.TITLE],
      [FilterFormFieldsEnum.DESCRIPTION]: shared.get(INIT_CONFIG_SHARED_KEY)?.[FilterFormFieldsEnum.DESCRIPTION],
    }) !==
    JSON.stringify({
      [FilterFormFieldsEnum.TITLE]: filterFormWatch?.[FilterFormFieldsEnum.TITLE],
      [FilterFormFieldsEnum.DESCRIPTION]: filterFormWatch?.[FilterFormFieldsEnum.DESCRIPTION],
    });

  const isFilterFormChanging =
    JSON.stringify(shared.get(INIT_CONFIG_SHARED_KEY)?.[FORM_NAME.FILTER_FORM]) !== JSON.stringify(currentFilterForm);
  const getChartFormValueToCompare = (chartFormValue?: Record<string, any>) => {
    const { [ChartFormFieldsEnum.FILTER_FIELDS]: filterField, ...restChartValue } = chartFormValue ?? {};
    return restChartValue;
  };
  const isChartFormChanging =
    JSON.stringify(getChartFormValueToCompare(shared.get(INIT_CONFIG_SHARED_KEY)?.[FORM_NAME.CHART_FORM])) !==
    JSON.stringify(getChartFormValueToCompare(currentChartForm));
  const isConfigChanging = isTitleOrDescriptionChanging || isFilterFormChanging || isChartFormChanging;

  return {
    isConfigChanging,
  };
};
