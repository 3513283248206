import React from "react";
import { List } from "@refinedev/antd";
import { FilterExploration } from "./component/filter/filter-exploration";

import { ShowChartResultMemo } from "./component/charts/show-chart-result";
import { FilterFormFieldsEnum } from "./enum";
import { Form } from "antd";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { ChipWrapper } from "./component/filter/form/extra-filter/chip/chips-wrapper";
import { css } from "@emotion/react";
import { FORM_NAME } from "@src/constant/ab-testing/user-count";
import { useCanEditAbTestExplore } from "@src/hooks/ab-testing/can/use-can-edit-ab-test";

export const ABTestExplore: React.FC = () => {
  const { filterForm, chartForm } = useABContext();

  const isUserAppliedFilter = Form.useWatch(FilterFormFieldsEnum.IS_FIRST_APPLIED, filterForm);
  const { canEdit } = useCanEditAbTestExplore();
  return (
    <List
      title={<span></span>}
      breadcrumb={null}
      headerProps={{
        extra: false,
      }}
    >
      <div
        css={
          !canEdit
            ? css`
                .ant-select-selector,
                input:not([aria-autocomplete="list"]),
                div.group,
                .ant-picker,
                button {
                  background: rgba(0, 0, 0, 0.04) !important;
                  color: rgba(0, 0, 0, 0.25) !important;
                  cursor: not-allowed !important;
                  pointer-events: none !important;
                  &:hover {
                    border-color: #d9d9d9 !important;
                  }
                }
                .ant-select {
                  &:hover {
                    .ant-select-clear {
                      display: none !important;
                    }
                  }
                }
                .ant-input-number-handler-wrap {
                  display: none !important;
                }
                .ant-input-number-outlined:hover {
                  border-color: #d9d9d9 !important;
                }
                span[role="button"],
                .canEdit {
                  color: rgba(0, 0, 0, 0.25) !important;
                  cursor: not-allowed !important;
                  pointer-events: none !important;
                  &:hover {
                    border-color: #d9d9d9 !important;
                  }
                }
              `
            : css``
        }
      >
        <FilterExploration form={filterForm} chartFormInstance={chartForm} />
        <ChipWrapper form={filterForm} />

        <div
          style={{
            display: isUserAppliedFilter ? "block" : "none",
          }}
        >
          <Form name={FORM_NAME.CHART_FORM} form={chartForm}>
            <ShowChartResultMemo form={chartForm} />
          </Form>
        </div>
      </div>
    </List>
  );
};
