import { FormInstance } from "antd/lib/form/Form";
import { Typography, Table, Collapse, Spin } from "antd";
import React from "react";
import { useLoadAbExploration } from "@src/hooks/ab-testing/use-ab-exploration-get-result";
import { EChartWrapper } from "../layout/echart-wrapper";
import ReactECharts from "echarts-for-react";
import { SupportedModelAlias } from "@src/constant/ab-testing/ab-testing-exploration.enum";
import { IQuery } from "@src/types/ab-testing-exploration";
import { useGetGeneralField } from "@src/hooks/ab-testing/use-get-general-field";
import { AbTestUserDistributionMemo } from "./user-distribution";
import { ChartLayoutWithUserPercent } from "../layout/chart-layout-with-user-percent";
import { Link } from "react-router-dom";
import { useTracking } from "react-tracking";
import { trackEventPayload } from "@src/util/track-event-payload";
import {
  ExtraSampleSizeName,
  ExtraSampleSizeTooltip,
  GeneralSampleSizeName,
  GeneralSampleSizeTooltip,
  InvalidSampleSizeTooltip,
  UserSampleSizeHelper,
} from "@src/util/ab-testing/user-count/user-sample-size";
import { ColumnType } from "antd/lib/table";
import { formatNumber } from "@src/util/number-format";

const { Text } = Typography;

const UserOverview: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { trackEvent } = useTracking();
  const generalFieldValues = useGetGeneralField({ form });
  const { productCode, generalFilters, explorationOptions, extraFilters, invalidUserFilters } = generalFieldValues;
  const [activePanelKeys, setActivePanelKeys] = React.useState<string[]>([]);

  const jsonQuery: IQuery = {
    dimensions: [],
    measures: ["user_count"],
    limit: 10000,
  };

  const modelAlias = SupportedModelAlias.USER_INFO;

  const { result: generalUserCountResult, isLoading: generalUserCountIsLoading } = useLoadAbExploration({
    productCode,
    modelAlias,
    query: {
      ...jsonQuery,
      filters: generalFilters,
    },
    options: explorationOptions,
    isSkip: activePanelKeys.indexOf("user-overview") === -1,
  });

  const generalUserCount =
    generalUserCountResult && generalUserCountResult.length > 0 ? generalUserCountResult[0]["user_count"] : 0;

  const { result: extraFilterUserCountResult, isLoading: extraFilterUserCountIsLoading } = useLoadAbExploration({
    productCode,
    modelAlias,
    query: {
      ...jsonQuery,
      filters: [...generalFilters, ...extraFilters],
    },
    options: explorationOptions,
    isSkip: activePanelKeys.indexOf("user-overview") === -1,
  });

  const extraFilterUserCount =
    extraFilterUserCountResult && extraFilterUserCountResult.length > 0
      ? extraFilterUserCountResult[0]["user_count"]
      : 0;

  const { result: invalidUserCountResult, isLoading: invalidUserCountIsLoading } = useLoadAbExploration({
    productCode,
    modelAlias,
    query: {
      ...jsonQuery,
      filters: [...generalFilters, ...extraFilters, ...invalidUserFilters],
    },
    options: explorationOptions,
  });

  const invalidUserCount =
    invalidUserCountResult && invalidUserCountResult.length > 0 ? invalidUserCountResult[0]["user_count"] : 0;

  const isLoading = generalUserCountIsLoading || invalidUserCountIsLoading || extraFilterUserCountIsLoading;

  const userSampleSizeHelper = new UserSampleSizeHelper();
  userSampleSizeHelper.setGeneralSampleSize(generalUserCount);
  userSampleSizeHelper.setExtraSampleSize(extraFilterUserCount);
  userSampleSizeHelper.setInvalidSampleSize(invalidUserCount);

  const dataSourcePreviousStep = userSampleSizeHelper.getDataSourceForPreviousStep();

  const columns: ColumnType<any>[] = [
    {
      key: "name",
      dataIndex: "name",
      title: "Name",
      width: "50%",
    },
    {
      key: "userCount",
      dataIndex: "userCount",
      title: "User count",
      width: "25%",
      render: (value: number) => formatNumber(value),
    },
    {
      key: "userPercent",
      dataIndex: "userPercent",
      title: "User percent",
      render: (value: number) => `${value}%`,
      width: "25%",
    },
  ];

  const chartSampleSizeOption: echarts.EChartsOption = {
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c}%",
    },
    toolbox: {
      right: "10%",
      feature: {
        dataView: { readOnly: false },
        restore: {},
        saveAsImage: {},
      },
    },
    legend: {
      align: "left",
      left: "5%",
      bottom: 10,
      orient: "horizontal",
      data: dataSourcePreviousStep.map((item) => item.name),
      formatter: (name) => {
        return name;
      },
    },
    color: ["#91cc75", "#fac858", "#5470c6", "#ee6666", "#73c0de", "#3ba272", "#fc8452", "#9a60b4", "#ea7ccc"],
    series: [
      {
        name: "",
        type: "funnel",
        left: "5%",
        top: 0,
        bottom: 0,
        width: "80%",
        height: "100%",
        min: 0,
        minSize: "10%",
        maxSize: "60%",
        sort: "none",
        orient: "horizontal",
        gap: 2,
        label: {
          show: true,
          position: "inside",
          formatter: (record) => {
            const _data = record.data as any;
            return `${formatNumber(_data?.userCount)} (${_data?.userPercent?.toFixed(2)}%)`;
          },
        },
        tooltip: {
          formatter: (record) => {
            const _data = record.data as any;
            if (_data?.name === GeneralSampleSizeName) {
              return GeneralSampleSizeTooltip;
            }
            if (_data?.name === ExtraSampleSizeName) {
              return ExtraSampleSizeTooltip;
            }
            return InvalidSampleSizeTooltip;
          },
        },
        labelLine: {
          length: 10,
          lineStyle: {
            width: 1,
            type: "solid",
          },
        },
        itemStyle: {
          borderColor: "#fff",
          borderWidth: 1,
        },
        emphasis: {
          label: {
            fontSize: 20,
          },
        },
        data: dataSourcePreviousStep,
      },
    ],
  };
  const isEmpty = dataSourcePreviousStep.length === 0;
  return (
    <Collapse
      className="ab-testing-collapse mb-4"
      onChange={(k) => {
        setActivePanelKeys(k as string[]);
        trackEvent(trackEventPayload.userCountCardStatus(k.length > 0 ? "card_expand" : "card_collapse"));
      }}
      items={[
        {
          key: "user-overview",
          label: invalidUserCountIsLoading ? (
            <Spin />
          ) : (
            <>
              <Text strong>User count:</Text> {formatNumber(invalidUserCount)}
            </>
          ),
          children: (
            <div>
              <ChartLayoutWithUserPercent
                chartKey="user-overview"
                loading={!!isLoading}
                renderChart={
                  <div className="flex ">
                    <div className="w-1/2">
                      <EChartWrapper isEmpty={isEmpty} height={300}>
                        <span className="font-bold">
                          Chart information:{" "}
                          <Link
                            to="https://docs.google.com/presentation/d/19BEBRU8HCsg1j8IoRVXPWWzICaiMB-uOFWljvcxY3Ko/edit#slide=id.g328ad478491_0_0"
                            target="_blank"
                          >
                            Link
                          </Link>
                        </span>
                        <ReactECharts
                          key={JSON.stringify(isLoading)}
                          option={chartSampleSizeOption}
                          style={{ height: "calc(100% - 50px)" }}
                        />
                      </EChartWrapper>
                    </div>
                    <div className="w-1/2 ml-4 mt-8">
                      <Table
                        id="step"
                        rowKey="step"
                        sticky={{
                          offsetHeader: 0,
                        }}
                        size="small"
                        dataSource={dataSourcePreviousStep}
                        columns={columns}
                        pagination={false}
                      />
                    </div>
                  </div>
                }
                hiddenMode
              />
              <AbTestUserDistributionMemo form={form} />
            </div>
          ),
        },
      ]}
    />
  );
};
export const UserOverviewMemo = React.memo(UserOverview);
