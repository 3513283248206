import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form, FormInstance } from "antd";

export const useCheckEnoughRequiredField = (form: FormInstance<any>) => {
  const productWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_CODE, form);
  const abaIdWatch = Form.useWatch(FilterFormFieldsEnum.ABA_ID, form);
  const platformWatch = Form.useWatch(FilterFormFieldsEnum.PLATFORM, form);
  const isHasPlatform = Array.isArray(platformWatch) && platformWatch?.length > 0;
  const cohortWatch = Form.useWatch(FilterFormFieldsEnum.COHORT_DATE, form);
  const maxDayDiffWatch = Form.useWatch(FilterFormFieldsEnum.MAX_DAY_DIFF, form);
  const isValidMaxDayDiff = typeof maxDayDiffWatch === "number" && maxDayDiffWatch !== undefined;

  const enoughFieldsRequired = !!productWatch && abaIdWatch && isHasPlatform && !!cohortWatch && isValidMaxDayDiff;

  const isAbaLoading = Form.useWatch(FilterFormFieldsEnum.IS_ABA_LOADING, form);
  const isAmaAppLoading = Form.useWatch(FilterFormFieldsEnum.IS_AMA_APP_LOADING, form);
  const isExpLoading = Form.useWatch(FilterFormFieldsEnum.IS_EXP_LOADING, form);

  const isRequireFieldLoading = isAbaLoading || isAmaAppLoading || isExpLoading;

  return {
    productWatch,
    abaIdWatch,
    platformWatch,
    isHasPlatform,
    cohortWatch,
    maxDayDiffWatch,
    enoughFieldsRequired,
    isRequireFieldLoading,
  };
};
