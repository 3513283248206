import { Portal } from "@src/components/portal";
import { ChartResult } from "./chart-result";
import { Button, Form, Popconfirm, Popover, Tooltip } from "antd";
import { SaveButton } from "@refinedev/antd";
import { CopyOutlined, LinkOutlined, ShareAltOutlined } from "@ant-design/icons";
import React from "react";
import { useDuplicateFirebaseExplore } from "@src/hooks/fb-explore/use-duplicate-firebase-explore";
import ShareLink from "@src/components/visualization/share-link";
import { useExploreContext } from "@src/contexts/explore-context";
import { FormInstance } from "antd/lib/form";
import { useUpdate } from "@refinedev/core";

export const ChartResultEdit: React.FC<{ exploration?: any; form?: FormInstance<any> }> = ({ exploration, form }) => {
  const { pivotConfig: pivotConfigContext } = useExploreContext();
  const platformWatch = Form.useWatch("platform", form);
  const [pivotConfig, setPivotConfig] = React.useState(exploration.vizState.pivotConfig);

  React.useEffect(() => {
    if (!pivotConfigContext) return;
    setPivotConfig(pivotConfigContext);
  }, [pivotConfigContext, exploration.vizState.pivotConfig]);

  const layout = {
    ...exploration,
    vizState: {
      ...exploration.vizState,
      pivotConfig: pivotConfig,
    },
  };

  const { handleDuplicate } = useDuplicateFirebaseExplore({
    id: exploration.id,
    form,
  });

  const onDuplicate = () => {
    handleDuplicate({});
  };

  const { mutateAsync: publishExploration } = useUpdate();
  const onPublishExploration = async () => {
    if (exploration?.isPublic) return;
    await publishExploration({
      resource: "explorations",
      id: exploration.id,
      values: {
        is_public: true,
      },
      successNotification: false,
      dataProviderName: "nestjsx",
      meta: {
        headers: {
          "Product-Code": exploration?.vizState.product_code,
        },
      },
    });
  };
  const isEmptyPlatform = !platformWatch || platformWatch.length === 0;

  return (
    <div className="h-full ">
      <ChartResult mode="edit" layout={layout} pivotConfig={pivotConfig} setPivotConfig={setPivotConfig} form={form} />
      <Portal containerId="actions-wrapper">
        <div className="absolute top-20 right-2 flex space-x-2">
          <Popover
            content={<ShareLink />}
            title={
              <div className="flex items-center">
                <LinkOutlined className="mr-2" />
                <div>Get link</div>
              </div>
            }
            trigger="click"
            className="flex items-center"
          >
            <Button
              type="link"
              onClick={async () => {
                console.log("Share button clicked");
                await onPublishExploration();
              }}
            >
              <ShareAltOutlined className="mr-2" />
              <div>Share</div>
            </Button>
          </Popover>
          <Tooltip title="Duplicate">
            <Popconfirm title="Are you sure duplicate this exploration?" onConfirm={onDuplicate}>
              <Button className="" icon={<CopyOutlined />}>
                Duplicate
              </Button>
            </Popconfirm>
          </Tooltip>

          <SaveButton disabled={isEmptyPlatform} htmlType="submit" className=" ">
            Save
          </SaveButton>
        </div>
      </Portal>
    </div>
  );
};
