import { Button, Form } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import React, { useState } from "react";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { useTracking } from "react-tracking";
import { trackEventPayload } from "@src/util/track-event-payload";
import { getABTestLocation } from "@src/util/ab-testing/get-ab-test-location";
import { useCheckEnoughRequiredField } from "@src/hooks/ab-testing/use-check-enough-required-field";
import { CUSTOM_EVENT_NAME } from "@src/constant/ab-testing/custom-event-name.enum";
import { useParsed } from "@refinedev/core";

interface SubmitButtonProps {
  form: FormInstance<any>;
}
export const SubmitButton: React.FC<SubmitButtonProps> = ({ form }) => {
  const params = useParsed();

  const { isCreateMode } = getABTestLocation();
  const { trackEvent } = useTracking();
  const { chartForm } = useABContext();

  const [currentFields, setCurrentFields] = useState<any>({});

  const {
    isRequireFieldLoading,
    enoughFieldsRequired,
    productWatch,
    abaIdWatch,
    cohortWatch,
    maxDayDiffWatch,
    platformWatch,
  } = useCheckEnoughRequiredField(form);

  const variantWatch = Form.useWatch(FilterFormFieldsEnum.VARIANTS, form);
  const variantsOptionsWatch = Form.useWatch(FilterFormFieldsEnum.VARIANTS_OPTIONS, form);

  const extraFilterConfirmedWatch = Form.useWatch(FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED, form);

  const isUserAppliedFilter = Form.useWatch(FilterFormFieldsEnum.IS_FIRST_APPLIED, form);

  const mergeField = {
    [FilterFormFieldsEnum.PRODUCT_CODE]: productWatch,
    [FilterFormFieldsEnum.ABA_ID]: abaIdWatch,
    [FilterFormFieldsEnum.VARIANTS]: variantWatch,
    [FilterFormFieldsEnum.PLATFORM]: platformWatch,
    [FilterFormFieldsEnum.COHORT_DATE]: cohortWatch,
    [FilterFormFieldsEnum.MAX_DAY_DIFF]: maxDayDiffWatch,
    [FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED]: extraFilterConfirmedWatch,
  };
  const isFieldChange = JSON.stringify(currentFields) !== JSON.stringify(mergeField);

  const isDisable = isRequireFieldLoading || !isFieldChange;

  const handleClick = React.useCallback(() => {
    if (!chartForm.getFieldsValue() || Object.keys(chartForm.getFieldsValue()).length === 0) return;
    form.validateFields().then(() => {
      form.setFieldValue(FilterFormFieldsEnum.IS_FIRST_APPLIED, true);
      form.submit();
      setCurrentFields(mergeField);
    });
  }, [chartForm, form, JSON.stringify(mergeField)]);

  React.useEffect(() => {
    setCurrentFields({});
  }, [params.id]);

  React.useEffect(() => {
    window.addEventListener(CUSTOM_EVENT_NAME.APPLY_FILTER, () => {
      handleClick();
    });
    return () => {
      window.removeEventListener(CUSTOM_EVENT_NAME.APPLY_FILTER, () => {
        return;
      });
    };
  }, [handleClick]);
  React.useEffect(() => {
    if (isCreateMode) return;
    if (isRequireFieldLoading) return;
    if (isDisable) return;
    if (isUserAppliedFilter) return;
    console.log("auto submit");
    if (!variantsOptionsWatch || variantsOptionsWatch?.length === 0) return;
    handleClick();
    trackEvent(trackEventPayload.applyFilter(form.getFieldsValue(), true));
  }, [form, isDisable, isUserAppliedFilter, isRequireFieldLoading, JSON.stringify(variantsOptionsWatch), isCreateMode]);

  return (
    <div className="pt-7 mb-2">
      <Form.Item className="ml-2">
        <Button
          type="primary"
          loading={isRequireFieldLoading}
          disabled={isDisable}
          onClick={() => {
            if (!enoughFieldsRequired) {
              form
                .validateFields([
                  FilterFormFieldsEnum.PRODUCT_CODE,
                  FilterFormFieldsEnum.ABA_ID,
                  FilterFormFieldsEnum.PLATFORM,
                ])
                .then(() => {
                  handleClick();
                  trackEvent(trackEventPayload.applyFilter(form.getFieldsValue()));
                });
              return;
            }
            handleClick();
            trackEvent(trackEventPayload.applyFilter(form.getFieldsValue()));
          }}
        >
          Apply
        </Button>
      </Form.Item>
    </div>
  );
};
