import { Card, Form, FormInstance, Input } from "antd";
import { ChartFormFieldsEnum } from "../../enum";
import { UserOverviewMemo } from "./user-overview";
import React from "react";
import { css } from "@emotion/react";
import { MetricPerformance } from "./metric-performance";

const ShowChartResult: React.FC<{ form: FormInstance }> = ({ form }) => {
  return (
    <>
      <UserOverviewMemo form={form} />

      <Card
        css={css`
          .ant-tabs-tab-active {
            background-color: white !important;
          }
        `}
      >
        <MetricPerformance form={form} />
      </Card>
      <Form.Item name={ChartFormFieldsEnum.FILTER_FIELDS} hidden>
        <Input />
      </Form.Item>
    </>
  );
};
export const ShowChartResultMemo = React.memo(ShowChartResult);
