import { Empty } from "antd";
import { ReactNode } from "react";

export const EChartWrapper: React.FC<{
  isEmpty?: boolean;
  children: ReactNode;
  description?: string;
  height?: number;
}> = ({ isEmpty = false, children, description, height = 600 }) => {
  if (isEmpty) {
    return (
      <div className="w-full h-[150px] flex items-center justify-center ">
        <Empty description={description ?? "No data"} />
      </div>
    );
  }
  return (
    <div
      style={{
        height: height,
      }}
      className={`overflow-y-scroll overflow-x-hidden`}
    >
      {children}
    </div>
  );
};
