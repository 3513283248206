import { ListButton, Show } from "@refinedev/antd";
import { useExploreContext } from "@src/contexts/explore-context";
import { Button, Card, Checkbox, Form } from "antd";
import React from "react";
import ExploreQueryNew from "@src/pages/dashboard/components/explore-builder-new";
import { useNavigation } from "@refinedev/core";
import { GeneralInfoWrapper } from "./form/general-info-wrapper";
import { ChartResultProvider } from "./form/chart-result-provider";
import { QueryBuilderChartLayout } from "@src/layouts/firebase-explore/query-builder-chart-layout";
import { ArrowLeftOutlined } from "@ant-design/icons";

export const FirebaseExploreShow: React.FC = () => {
  const { push } = useNavigation();
  const [_form] = Form.useForm();
  const { form: formContext, exploration, isLoading } = useExploreContext();
  const form = formContext ?? _form;
  const formLoading = Form.useWatch("form_loading", form);

  return (
    <Show
      isLoading={isLoading || !!formLoading}
      title="Show exploration"
      headerButtons={<ListButton onClick={() => push("/")}>Explorations</ListButton>}
      contentProps={{
        className: "bg-transparent shadow-none card-custom",
      }}
      canEdit
      goBack={
        <Button
          type="text"
          onClick={(e) => {
            e.stopPropagation();
            push("/");
          }}
        >
          <ArrowLeftOutlined />
        </Button>
      }
      footerButtonProps={{
        hidden: true,
      }}
      wrapperProps={{
        className: "base-custom-container",
      }}
      breadcrumb={null}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          form_loading: false,
          name: exploration?.name,
          product_code: exploration?.vizState.product_code,
          description: exploration?.description,
          platform: exploration?.platform,
          "time-range": exploration?.["time-range"],
          "custom-time-range": exploration?.["custom-time-range"],
        }}
      >
        <Card size="small" title={"General Information"} className=" mb-2 ">
          <div className="flex mb-4">
            <div className="w-3/4">
              <GeneralInfoWrapper form={form} mode="edit" />
            </div>
            <div id="actions-wrapper" className="self-end w-1/4" />
          </div>
        </Card>

        <QueryBuilderChartLayout
          autoSaveId="explore-query-builder-edit"
          queryBuilderComponent={<ExploreQueryNew />}
          showResultComponent={<ChartResultProvider form={form} mode="view" exploration={exploration} />}
        />
        <Form.Item name="form_loading" hidden valuePropName="checked">
          <Checkbox />
        </Form.Item>
      </Form>
    </Show>
  );
};
