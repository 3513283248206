import { Button, Popover } from "antd";
import ShareLink from "@src/components/visualization/share-link";
import { ExclamationCircleFilled, LinkOutlined, ShareAltOutlined } from "@ant-design/icons";
import { useParsed, useUpdate } from "@refinedev/core";
import { INIT_CONFIG_SHARED_KEY } from "../edit";
import { shared } from "@src/util/shared";
import React from "react";
import { useClickOutside } from "@src/hooks/use-click-outside";

interface IPropsAbShareConfig {
  hasConfigChanged: boolean;
  handleClick: (isPreventNotification?: boolean) => Promise<void>;
}
export const ABShareConfig: React.FC<IPropsAbShareConfig> = ({ hasConfigChanged, handleClick }) => {
  const [openPopover, setOpenPopover] = React.useState(false);
  const params = useParsed();
  const { mutateAsync: publishExploration } = useUpdate();
  const contentRef = useClickOutside<HTMLDivElement>(() => {
    setTimeout(() => {
      setOpenPopover(false);
    }, 0);
  });

  const onPublishExploration = async () => {
    const { isPublic, productCode } = shared.get(INIT_CONFIG_SHARED_KEY);
    if (!params?.id || !productCode) return;
    if (isPublic) return;
    await publishExploration({
      resource: "explorations",
      id: params?.id,
      values: {
        is_public: true,
      },
      successNotification: false,
      dataProviderName: "nestjsx",
      meta: {
        headers: {
          "Product-Code": productCode,
        },
      },
    });
  };

  return (
    <Popover
      open={openPopover}
      content={
        <ShareTooltipContent
          eleRef={contentRef}
          hasConfigChanging={hasConfigChanged}
          onConfirmSave={handleClick}
          onPublishExploration={onPublishExploration}
        />
      }
      destroyTooltipOnHide
      className="flex items-center"
    >
      <Button
        type="link"
        className="flex items-center"
        onClick={() => {
          if (!openPopover) {
            setOpenPopover(true);
          }
          if (hasConfigChanged) return;
          onPublishExploration();
        }}
      >
        <ShareAltOutlined className="mr-2" />
        Share
      </Button>
    </Popover>
  );
};

interface IPropsShareTooltipContent {
  eleRef: React.RefObject<HTMLDivElement>;
  hasConfigChanging: boolean;
  onConfirmSave: (isPreventNotification?: boolean) => Promise<void>;
  onPublishExploration: () => void;
}
const ShareTooltipContent: React.FC<IPropsShareTooltipContent> = ({
  eleRef,
  hasConfigChanging,
  onConfirmSave,
  onPublishExploration,
}) => {
  const [forceShowShareLink, setForceShowShareLink] = React.useState(!hasConfigChanging);
  const params = useParsed();

  React.useEffect(() => {
    return () => {
      setForceShowShareLink(false);
    };
  }, []);

  const onAfterClickNotification = async (status?: boolean) => {
    if (status) {
      await onConfirmSave(true);
      await onPublishExploration();
    }
    setForceShowShareLink(true);
  };

  return (
    <div ref={eleRef}>
      {forceShowShareLink && (
        <div>
          <div className="flex items-center">
            <LinkOutlined className="mr-2" />
            <div>Get link</div>
          </div>
          <ShareLink customLink={`${window.location.origin}/explorations/ab-test/${params?.id}`} />
        </div>
      )}
      {!forceShowShareLink && (
        <div>
          <p className="max-w-[300px]">
            <ExclamationCircleFilled className="text-yellow-500 mr-2" />
            Your changes are not saved. Do you want to save before share?
          </p>

          <div className="flex justify-end gap-2 mt-2">
            <Button
              type="text"
              size="small"
              onClick={() => {
                onAfterClickNotification(false);
              }}
            >
              Share anyway
            </Button>
            <Button
              type="primary"
              className="mr-2"
              size="small"
              onClick={() => {
                onAfterClickNotification(true);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
