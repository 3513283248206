/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigation } from "@refinedev/core";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { Button, Form, Input, Modal, Tooltip, Typography } from "antd";
import { FilterFormFieldsEnum } from "../enum";
import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { DEFAULT_TITLE } from "../edit";
import { getABTestLocation } from "@src/util/ab-testing/get-ab-test-location";

const TitleModal = FilterFormFieldsEnum.TITLE + "Modal";
const DescriptionModal = FilterFormFieldsEnum.DESCRIPTION + "Modal";

export const TitleABConfig: React.FC = () => {
  const { isCreateMode } = getABTestLocation();
  const [isCustomTitle, setIsCustomTitle] = useState<boolean>(false);
  const { push } = useNavigation();

  const [openSave, setOpenSave] = useState<boolean>(false);
  const { filterForm } = useABContext();

  const productCode = Form.useWatch(FilterFormFieldsEnum.PRODUCT_CODE, filterForm);
  const abaId = Form.useWatch(FilterFormFieldsEnum.ABA_ID, filterForm);

  const title =
    Form.useWatch(FilterFormFieldsEnum.TITLE, filterForm) ?? filterForm.getFieldValue(FilterFormFieldsEnum.TITLE);

  const description =
    Form.useWatch(FilterFormFieldsEnum.DESCRIPTION, filterForm) ??
    filterForm.getFieldValue(FilterFormFieldsEnum.DESCRIPTION);

  React.useEffect(() => {
    if (!isCreateMode) return;
    if (isCustomTitle) return;
    let generateTitle = `${DEFAULT_TITLE}`;
    if (productCode) {
      generateTitle += ` - ${productCode}`;
    }
    if (abaId) {
      generateTitle += ` - ${abaId}`;
    }

    filterForm.setFieldValue(FilterFormFieldsEnum.TITLE, generateTitle);
  }, [productCode, abaId, isCustomTitle, isCreateMode]);

  return (
    <div className="flex ">
      <Button
        type="text"
        onClick={(e) => {
          e.stopPropagation();
          push("/");
        }}
      >
        <ArrowLeftOutlined />
      </Button>
      <div className="ab-title-group">
        <div className="flex items-center ">
          <Typography.Title
            className="truncate max-w-[300px] sm:max-w-[450px] xl:max-w-[600px] 2xl:max-w-[900px] m-0"
            level={3}
          >
            {title}
          </Typography.Title>
          <EditOutlined
            className="text-blue-500 ml-4 cursor-pointer"
            onClick={() => {
              setOpenSave(true);
            }}
          />
        </div>
        <Tooltip title={description}>
          <Typography.Paragraph className="truncate max-w-[300px] sm:max-w-[450px] xl:max-w-[600px] 2xl:max-w-[900px] font-normal text-gray-500 m-0">
            {description}
          </Typography.Paragraph>
        </Tooltip>
      </div>

      <Modal
        centered
        width={500}
        title="Update title"
        open={openSave}
        afterOpenChange={(isOpen) => {
          if (isOpen) {
            filterForm.setFieldValue(TitleModal, title);
            filterForm.setFieldValue(DescriptionModal, description);
          }
        }}
        onOk={() => {
          filterForm.validateFields([TitleModal]).then(() => {
            filterForm.setFieldValue(FilterFormFieldsEnum.TITLE, filterForm.getFieldValue(TitleModal));
            filterForm.setFieldValue(FilterFormFieldsEnum.DESCRIPTION, filterForm.getFieldValue(DescriptionModal));
            setOpenSave(false);
          });
        }}
        onCancel={() => {
          filterForm.setFieldValue(TitleModal, filterForm.getFieldValue(FilterFormFieldsEnum.TITLE));
          filterForm.setFieldValue(DescriptionModal, filterForm.getFieldValue(FilterFormFieldsEnum.DESCRIPTION));
          setOpenSave(false);
        }}
        destroyOnClose
      >
        <Form.Item
          label={<span className="w-[80px] flex">Title</span>}
          name={TitleModal}
          required
          rules={[
            {
              required: true,
              message: "Please input title!",
              validator(rule, value, callback) {
                if (value?.trim()?.length === 0) {
                  callback("Please input title!");
                } else {
                  callback();
                }
              },
            },
          ]}
        >
          <Input
            value={title}
            placeholder="title"
            onChange={() => {
              setIsCustomTitle(true);
            }}
          />
        </Form.Item>
        <Form.Item label={<span className="w-[88px] flex">Description</span>} name={DescriptionModal}>
          <TextArea value={description} placeholder="description" />
        </Form.Item>
      </Modal>

      <Form.Item name={FilterFormFieldsEnum.TITLE} hidden>
        <Input />
      </Form.Item>
      <Form.Item name={FilterFormFieldsEnum.DESCRIPTION} hidden>
        <Input />
      </Form.Item>
    </div>
  );
};
