export enum FilterFormFieldsEnum {
  TITLE = "title",
  DESCRIPTION = "description",
  PRODUCT_CODE = "product_code",
  PRODUCT_TOKEN = "product_token",
  PLATFORM = "platform",
  ABA_ID = "aba_id",
  AMA_APP_IDS = "ama_app_ids",
  VARIANTS = "variants",
  VARIANTS_OPTIONS = "variants_options",
  COHORT_DATE = "cohort_date",
  MAX_DAY_DIFF = "max_day_diff",

  //extra filter
  INVALID_COUNTRIES = "invalid_countries",
  USERS_JOIN_MULTIPLE_GROUPS = "users_join_multiple_groups",
  MULTI_USER_PSEUDO_ID = "multi_user_pseudo_id",
  LATE_ONBOARDING_DATE = "late_onboarding_date",

  EXTRA_INSTALL_DATE = "extra_install_date",
  EXTRA_ONBOARDING_DATE = "extra_onboarding_date",
  EXTRA_COUNTRY = "extra_country",
  EXTRA_MEDIA_SOURCE = "extra_media_source",
  EXTRA_DEVICE_MODAL = "extra_device_modal",
  EXTRA_DEVICE_CATEGORY = "extra_device_category",
  EXTRA_FIRST_APP_VERSION = "extra_first_app_version",
  EXTRA_LAST_APP_VERSION = "extra_last_app_version",

  EXTRA_FILTER_VALUES_CONFIRMED = "extra_filter_values_confirmed",

  // support
  IS_ABA_ID_CHANGED = "is_aba_id_changed",
  IS_FIRST_APPLIED = "is_first_applied",
  IS_SHOW_CHIP = "is_show_chip",
  IS_OPEN_MODAL = "is_open_modal",
  IS_ABA_LOADING = "is_aba_id_loading",
  IS_AMA_APP_LOADING = "is_ama_app_loading",
  IS_EXP_LOADING = "is_exp_loading",
}
export enum ChartFormFieldsEnum {
  FILTER_FIELDS = "filters_field",
  TIME_RANGE = "time_range",
  EVENT_NAME = "event_name",
  EVENT_PARAM = "event_param",
  PARAM_VALUE = "param_value",
  CUSTOM_TIME_RANGE = "custom_time_range",
  START_DATE = "start_date",
  END_DATE = "end_date",
  MAX_DAY_DIFF = "max_day_diff",
  AD_UNITS = "ad_units",
  AD_NETWORK = "ad_networks",
  PACKAGE = "package",

  //metric filter

  METRIC_TIME_RANGE = "metric_time_range",
  METRIC_BASE_VARIANTS = "metric_base_variants",

  METRIC_TAB_NAME = "metric_tab_name",
  METRIC_TARGET_MODEL = "target_model",
  METRIC_MEASURE = "measure_name",
  METRIC_MEASURE_OPTIONS = "measure_options",
  METRIC_SIGNIFICANCE_LEVEL = "metric_significance_level",
  METRIC_FILTER = "metric_filter",
  METRIC_BREAKDOWN = "metric_breakdown",

  METRIC_APPLIED = "metric_applied",
  METRIC_TAB_ITEMS = "metric_tab_items",

  //support
  EVENT_PARAM_OPTIONS = "event_param_options",
  IS_ALLOW_GET_USER_EVENT_ENGAGEMENT = "is_allow_get_user_event_engagement",

  //metric measure
  METRIC_MEASURE_VALUE = "measure_value",
  METRIC_MEASURE_TYPE = "measure_type",
  NUMERATOR = "numerator",
  DENOMINATOR = "denominator",
}
