import { useCan } from "@refinedev/core";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form } from "antd";

export const useCanEditAbTestExplore = () => {
  const { filterForm } = useABContext();
  const productCode = Form.useWatch(FilterFormFieldsEnum.PRODUCT_CODE, filterForm);
  const { data: dataCanEdit } = useCan({
    resource: "ab_test_exploration",
    action: productCode ? "create" : "list",
    params: {
      productCode,
    },
  });
  return {
    canEdit: dataCanEdit?.can,
  };
};
