import { Create } from "@refinedev/antd";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import React from "react";
import {
  parsedChartFormValueFromApi,
  parsedFilterFormValueFromApi,
} from "@src/util/ab-testing/handle-init-value-for-ab-test";
import { ABPortalContainerId } from "@src/constant/ab-testing/portal-container-id.enum";
import { ABTestConfigSessionKey } from "@src/constant/ab-testing/ab-testing-exploration.enum";
import { css } from "@emotion/react";
import { shared } from "@src/util/shared";
import { INIT_CONFIG_SHARED_KEY } from "./edit";
import { useCanEditAbTestExplore } from "@src/hooks/ab-testing/can/use-can-edit-ab-test";

export const ABTestExploreCreate: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { filterForm, chartForm } = useABContext();
  const { canEdit } = useCanEditAbTestExplore();
  React.useEffect(() => {
    if (!filterForm) return;

    const abConfigSession = sessionStorage.getItem(ABTestConfigSessionKey);

    const timeoutId = setTimeout(() => {
      try {
        if (!abConfigSession) {
          filterForm.setFieldsValue(parsedFilterFormValueFromApi({}));
          chartForm.setFieldsValue(parsedChartFormValueFromApi({}));
          return;
        }
        const parsedAbConfigSession = JSON.parse(abConfigSession);
        filterForm.setFieldsValue(parsedFilterFormValueFromApi(parsedAbConfigSession?.filterForm ?? {}));

        chartForm.setFieldsValue(parsedChartFormValueFromApi(parsedAbConfigSession?.chartForm ?? {}));
        sessionStorage.removeItem(ABTestConfigSessionKey);
      } catch (error) {
        console.error("error ", error);
        filterForm.setFieldsValue(parsedFilterFormValueFromApi({}));
      }
    }, 200);
    return () => clearTimeout(timeoutId);
  }, [chartForm, filterForm]);

  React.useEffect(() => {
    return () => {
      shared.delete(INIT_CONFIG_SHARED_KEY);
    };
  }, []);

  return (
    <div
      css={
        canEdit
          ? css`
              .ant-page-header-content {
                padding: 0;
              }
            `
          : css`
              .ant-page-header-content {
                padding: 0;
              }
              .ab-title-group {
                span {
                  background: rgba(0, 0, 0, 0.04) !important;
                  color: rgba(0, 0, 0, 0.25) !important;
                  cursor: not-allowed !important;
                  pointer-events: none !important;
                  &:hover {
                    border-color: #d9d9d9 !important;
                  }
                }
              }
              .ab-save-group {
                button {
                  background: rgba(0, 0, 0, 0.04) !important;
                  color: rgba(0, 0, 0, 0.25) !important;
                  cursor: not-allowed !important;
                  pointer-events: none !important;
                  &:hover {
                    border-color: #d9d9d9 !important;
                  }
                }
              }
            `
      }
    >
      <Create
        title={<div id={ABPortalContainerId.AB_TITLE_COMPONENT} />}
        goBack={null}
        breadcrumb={null}
        footerButtonProps={{
          hidden: true,
        }}
        contentProps={{
          className: "bg-transparent shadow-none card-custom",
        }}
        wrapperProps={{
          className: "base-custom-container",
        }}
        headerButtons={<div id={ABPortalContainerId.AB_SAVE_COMPONENT} />}
      >
        {children}
      </Create>
    </div>
  );
};
