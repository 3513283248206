import { SupportedMeasureType } from "@src/constant/ab-testing/ab-testing-exploration.enum";
import { ChartFormFieldsEnum, FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { extractRatioParts, extractRetentionEventName } from "../extract-ratio-parts";
import dayjs from "dayjs";
import { ALL_EXTRA_FIELDS_FILTER } from "@src/pages/ab-test-explore/contanst";
import { convertDateRangeToDayJS } from "../time-form";
import { FilterFormInstanceType } from "@src/contexts/ab-testing/ab-context";
import { DEFAULT_TITLE } from "@src/pages/ab-test-explore/edit";
import { MEASURE_RETENTION_BY_EVENT_NAME } from "@src/constant/ab-testing";

const getValueFromTemplate = (measureOption: any, measureValue: string) => {
  const regex = measureOption?.matched_regex;
  if (!regex) return {};

  if (measureOption?.[ChartFormFieldsEnum.METRIC_MEASURE_TYPE] === SupportedMeasureType.RATIO) {
    const matched = extractRatioParts(measureValue, regex) ?? {
      numerator: null,
      denominator: null,
    };

    return {
      [ChartFormFieldsEnum.NUMERATOR]: matched.numerator,
      [ChartFormFieldsEnum.DENOMINATOR]: matched.denominator,
    };
  }
  if (measureOption?.name === MEASURE_RETENTION_BY_EVENT_NAME) {
    const matched = extractRetentionEventName(measureValue, regex) ?? {
      event_name: null,
    };
    return {
      [ChartFormFieldsEnum.EVENT_NAME]: matched?.event_name,
    };
  }
  return {};
};

export const parsedChartFormValueFromApi = (values: Record<string, any>) => {
  const metricTabItems = values?.[ChartFormFieldsEnum.METRIC_TAB_ITEMS] ?? [];

  const metricPerformanceDetail = metricTabItems.reduce((acc: Record<string, any>, item: Record<string, any>) => {
    const parsedMeasureOptions = JSON.parse(item?.[ChartFormFieldsEnum.METRIC_MEASURE_OPTIONS] ?? "{}");
    const valuesFromTemplate = getValueFromTemplate(
      parsedMeasureOptions,
      item[ChartFormFieldsEnum.METRIC_MEASURE_VALUE],
    );
    acc[item.key] = {
      ...item,
      ...valuesFromTemplate,
    };
    return acc;
  }, {});

  const mappingWithDefault = [
    {
      key: ChartFormFieldsEnum.CUSTOM_TIME_RANGE,
      value: values?.[ChartFormFieldsEnum.CUSTOM_TIME_RANGE]?.map((item: string) => dayjs(item)),
    },
    {
      key: ChartFormFieldsEnum.METRIC_TIME_RANGE,
      value: values?.[ChartFormFieldsEnum.METRIC_TIME_RANGE]?.map((item: string) => dayjs(item)),
    },
  ];

  const dataWithDefault: Record<string, any> = {};

  mappingWithDefault.forEach(({ key, value }) => {
    dataWithDefault[key] = value;
  });

  return {
    ...values,
    ...metricPerformanceDetail,
    ...dataWithDefault,
  };
};

export const parsedFilterFormValueFromApi = (values: Record<string, any>) => {
  const filterField: Record<string, any> = {
    [FilterFormFieldsEnum.TITLE]: DEFAULT_TITLE,
    [FilterFormFieldsEnum.DESCRIPTION]: "",
    ...values,
    [FilterFormFieldsEnum.VARIANTS]: values?.[FilterFormFieldsEnum.VARIANTS]?.sort(),
    [FilterFormFieldsEnum.VARIANTS_OPTIONS]: values?.[FilterFormFieldsEnum.VARIANTS_OPTIONS]?.sort((a: any, b: any) =>
      a.value?.localeCompare(b.value),
    ),
    [FilterFormFieldsEnum.IS_ABA_ID_CHANGED]: false,
  };
  const filterValuesConfirmed: Record<string, any> = {};
  ALL_EXTRA_FIELDS_FILTER.forEach((field) => {
    const value = filterField[field];

    if (value) {
      filterValuesConfirmed[field] = value;
    }
  });
  const initFilterOutUserField = {
    [FilterFormFieldsEnum.INVALID_COUNTRIES]: true,
    [FilterFormFieldsEnum.MULTI_USER_PSEUDO_ID]: true,
    [FilterFormFieldsEnum.USERS_JOIN_MULTIPLE_GROUPS]: true,
    [FilterFormFieldsEnum.LATE_ONBOARDING_DATE]: true,
    // [FilterFormFieldsEnum.LATE_ONBOARDING_DATE]: false, // true as default later
  };

  return {
    ...initFilterOutUserField,
    ...filterField,
    [FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED]: JSON.stringify({
      ...initFilterOutUserField,
      ...filterValuesConfirmed,
    }),
    [FilterFormFieldsEnum.IS_SHOW_CHIP]: true,
    [FilterFormFieldsEnum.EXTRA_INSTALL_DATE]: convertDateRangeToDayJS(
      values?.[FilterFormFieldsEnum.EXTRA_INSTALL_DATE],
    ),
    [FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE]: convertDateRangeToDayJS(
      values?.[FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE],
    ),
  } as FilterFormInstanceType;
};
