import { useCreate, useOne, useParsed } from "@refinedev/core";
import { useState } from "react";

export const useDuplicateAbTestConfig = ({ type }: { type: "explore-listing" | "ab-test-edit" }) => {
  const [id, setId] = useState<string | undefined>(undefined);
  const params = useParsed();
  const _id = id || params.id;
  const { mutateAsync: createNewConfig } = useCreate();
  const { refetch, isFetching } = useOne({
    id: _id,
    resource: "explorations",
    dataProviderName: "nestjsx",
    queryOptions: {
      enabled: false,
      queryKey: ["explorations", "duplicate"],
      onSuccess: async (data) => {
        delete data.data?.updated_at;
        delete data.data?.updated_by;
        delete data.data?.created_at;
        delete data.data?.is_public;
        delete data.data?.id;

        const _data = {
          ...data?.data,
          title: data?.data?.title + " (Copy)",
        };
        const newConfigDuplicated = await createNewConfig({
          dataProviderName: "nestjsx",
          resource: "explorations",
          values: _data,
          meta: {
            headers: {
              "product-code": data?.data?.product_code,
            },
          },
        });
        if (newConfigDuplicated?.data) {
          if (type === "explore-listing") {
            window.location.replace(`${window.location.origin}/explorations/ab-test/${newConfigDuplicated?.data?.id}`);
          } else {
            window.open(`${window.location.origin}/explorations/ab-test/${newConfigDuplicated?.data?.id}`, "_blank");
          }
        }
      },
    },
  });

  const handleRefetch = (id?: any) => {
    setId(id);
    setTimeout(() => {
      refetch();
    }, 200);
  };
  return {
    refetch: handleRefetch,
    loading: isFetching,
  };
};
