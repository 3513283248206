import { CopyOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { useDuplicateAbTestConfig } from "@src/hooks/ab-testing/use-duplicate-ab-test-config";
import { useClickOutside } from "@src/hooks/use-click-outside";
import { Button, Popover } from "antd";
import React from "react";

interface IPropsAbDuplicateConfig {
  hasConfigChanged: boolean;
  handleClick: (isPreventNotification?: boolean) => Promise<void>;
}

export const ABDuplicateConfigButton: React.FC<IPropsAbDuplicateConfig> = ({ hasConfigChanged, handleClick }) => {
  const [open, setOpen] = React.useState(false);

  const { loading, refetch } = useDuplicateAbTestConfig({
    type: "ab-test-edit",
  });

  return (
    <div className="canEdit">
      <Popover
        open={open}
        content={
          <DuplicateContent
            handleClick={handleClick}
            refetch={refetch}
            hasConfigChanged={hasConfigChanged}
            setOpen={setOpen}
          />
        }
        trigger={"click"}
        destroyTooltipOnHide
      >
        <Button
          className="duplicate-button"
          loading={loading}
          icon={<CopyOutlined />}
          onClick={() => {
            setOpen(true);
          }}
        >
          Duplicate
        </Button>
      </Popover>
    </div>
  );
};

const DuplicateContent: React.FC<{
  hasConfigChanged: boolean;
  handleClick: (isPreventNotification?: boolean) => Promise<void>;
  refetch: (id?: any) => void;
  setOpen: (value: React.SetStateAction<boolean>) => void;
}> = ({ hasConfigChanged, handleClick, refetch, setOpen }) => {
  const [isConfigChanging] = React.useState(hasConfigChanged);

  const ref = useClickOutside<HTMLDivElement>(() => {
    setTimeout(() => {
      setOpen(false);
    }, 0);
  });

  const handleConfirm = async () => {
    refetch();
  };
  return (
    <div ref={ref}>
      <p className="max-w-[300px]">
        <ExclamationCircleFilled className="text-yellow-500 mr-2" />
        {isConfigChanging
          ? "Your changes are not saved. Do you want to save the update of this exploration before duplicate?"
          : "Are you sure duplicate this exploration?"}
      </p>
      <div className="flex justify-end gap-2">
        {!hasConfigChanged && (
          <Button
            type="text"
            size="small"
            className="text-[12px]"
            onClick={() => {
              setOpen(false);
            }}
          >
            No
          </Button>
        )}

        <Button
          type={hasConfigChanged ? "text" : "primary"}
          size="small"
          className="text-[12px]"
          onClick={async () => {
            await handleConfirm();
            setOpen(false);
          }}
        >
          {hasConfigChanged ? "Duplicate Anyway" : "Yes"}
        </Button>
        {isConfigChanging && (
          <Button
            type="primary"
            size="small"
            className="text-[12px]"
            onClick={async () => {
              await handleClick(true);
              await handleConfirm();
              setOpen(false);
            }}
          >
            Save & Duplicate
          </Button>
        )}
      </div>
    </div>
  );
};
