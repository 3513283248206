import { FORM_NAME } from "@src/constant/ab-testing/user-count";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { INIT_CONFIG_SHARED_KEY } from "@src/pages/ab-test-explore/edit";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import {
  parsedChartFormValueFromApi,
  parsedFilterFormValueFromApi,
} from "@src/util/ab-testing/handle-init-value-for-ab-test";
import { shared } from "@src/util/shared";

export const useUpdateCacheInitAbConfig = () => {
  const { getAndFormatFilterForm, getAndFormatChartForm } = useABContext();

  const cacheAbConfig = (data: Record<string, any>) => {
    const parsedGeneralConfig = parsedFilterFormValueFromApi(data?.config?.generalConfig);
    const _generalConfig = getAndFormatFilterForm(parsedGeneralConfig);

    const parsedChartConfig = parsedChartFormValueFromApi(data?.config?.chartConfig);
    const _chartConfig = getAndFormatChartForm(parsedChartConfig);

    shared.set(INIT_CONFIG_SHARED_KEY, {
      isPublic: data.is_public,
      owner: data.owner,
      productCode: data.product_code,
      [FilterFormFieldsEnum.TITLE]: data.title,
      [FilterFormFieldsEnum.DESCRIPTION]: data.description,
      [FORM_NAME.FILTER_FORM]: _generalConfig,
      [FORM_NAME.CHART_FORM]: _chartConfig,
    });
  };
  return { cacheAbConfig };
};
