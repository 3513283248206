import { FilterFormInstanceType } from "@src/contexts/ab-testing/ab-context";
import { FilterFormFieldsEnum } from "../enum";
import { FilterOperator } from "@src/constant/ab-testing/ab-testing-exploration.enum";
import { COHORT_DATE } from "../contanst";
import { IQueryOptions } from "@src/types/ab-testing-exploration";

const getExplorationFilter = (member: string, operator: FilterOperator, values: any) => {
  if (values === undefined || values === null) return null;

  if (Array.isArray(values)) {
    return {
      member,
      operator,
      values: values,
    };
  } else {
    return {
      member,
      operator,
      values: [values],
    };
  }
};

export type ValueOfFilterFormType = {
  productCode: string | undefined;
  abaId: string | undefined;
  variants: string[] | undefined;
  variants_options: { label: string | undefined; value: string }[] | undefined;
  installDate: any;
  platforms: string[] | undefined;
  amaAppIds: string[] | undefined;
  cohortDate: string;
  maxDayDiff: number;
  onboardingDate: any;
  countryCode: any;
  mediaSource: any;
  deviceModel: any;
  deviceCategory: any;
  firstAppVersion: any;
  lastAppVersion: any;
  generalFilters: {
    member: string;
    operator: FilterOperator;
    values: any[];
  }[];
  extraFilters: {
    member: string;
    operator: FilterOperator;
    values: any[];
  }[];
  invalidUserFilters: {
    member: string;
    operator: FilterOperator;
    values: any[];
  }[];
  explorationFilters: {
    member: string;
    operator: FilterOperator;
    values: any[];
  }[];
  explorationOptions: IQueryOptions;
  getExpAliasById: (expId: string) => string;
};

export const getValueOfFiltersForm = (filterFields?: FilterFormInstanceType): ValueOfFilterFormType => {
  const {
    [FilterFormFieldsEnum.PRODUCT_CODE]: productCode,
    [FilterFormFieldsEnum.ABA_ID]: abaId,
    [FilterFormFieldsEnum.VARIANTS]: variants,
    [FilterFormFieldsEnum.VARIANTS_OPTIONS]: variants_options,
    [FilterFormFieldsEnum.PLATFORM]: platforms,
    [FilterFormFieldsEnum.AMA_APP_IDS]: amaAppIds,
    [FilterFormFieldsEnum.COHORT_DATE]: cohortDate,
    [FilterFormFieldsEnum.MAX_DAY_DIFF]: maxDayDiff,
    [FilterFormFieldsEnum.EXTRA_FILTER_VALUES_CONFIRMED]: extraFields,
  } = filterFields ?? {};

  let parsedExtraFields;

  try {
    parsedExtraFields = JSON.parse(extraFields ?? "{}");
  } catch (error) {
    console.log("Error parsing extra fields", error);
    parsedExtraFields = {};
  }

  const {
    [FilterFormFieldsEnum.EXTRA_INSTALL_DATE]: installDate,
    [FilterFormFieldsEnum.EXTRA_ONBOARDING_DATE]: onboardingDate,
    [FilterFormFieldsEnum.EXTRA_COUNTRY]: countryCode,
    [FilterFormFieldsEnum.EXTRA_MEDIA_SOURCE]: mediaSource,
    [FilterFormFieldsEnum.EXTRA_DEVICE_MODAL]: deviceModel,
    [FilterFormFieldsEnum.EXTRA_DEVICE_CATEGORY]: deviceCategory,
    [FilterFormFieldsEnum.EXTRA_FIRST_APP_VERSION]: firstAppVersion,
    [FilterFormFieldsEnum.EXTRA_LAST_APP_VERSION]: lastAppVersion,
    [FilterFormFieldsEnum.INVALID_COUNTRIES]: invalidCountry,
    [FilterFormFieldsEnum.USERS_JOIN_MULTIPLE_GROUPS]: userJoinMultiPleGroups,
    [FilterFormFieldsEnum.MULTI_USER_PSEUDO_ID]: multiUserPseudoId,
    [FilterFormFieldsEnum.LATE_ONBOARDING_DATE]: lateOnboardingDate,
  } = parsedExtraFields;

  const generalFilters = [
    getExplorationFilter("aba_id", FilterOperator.EQUALS, abaId),
    getExplorationFilter("exp_group", FilterOperator.EQUALS, variants),
    getExplorationFilter("ama_app_id", FilterOperator.EQUALS, amaAppIds),
  ].filter((f) => !!f);

  const invalidUserFilters = [
    getExplorationFilter("is_valid_country", FilterOperator.EQUALS, invalidCountry ? !!invalidCountry : invalidCountry),
    getExplorationFilter(
      "in_multi_exp_group",
      FilterOperator.EQUALS,
      userJoinMultiPleGroups ? !userJoinMultiPleGroups : userJoinMultiPleGroups,
    ),
    getExplorationFilter(
      "has_multi_user_pseudo_id",
      FilterOperator.EQUALS,
      multiUserPseudoId ? !multiUserPseudoId : multiUserPseudoId,
    ),
    getExplorationFilter(
      "is_late_signal",
      FilterOperator.EQUALS,
      lateOnboardingDate ? !lateOnboardingDate : lateOnboardingDate,
    ),
  ].filter((f) => !!f);

  const extraFilters = [
    getExplorationFilter("install_date_tzutc", FilterOperator.BETWEEN, installDate),
    getExplorationFilter("first_signal_date", FilterOperator.BETWEEN, onboardingDate),
    getExplorationFilter("country_code", FilterOperator.EQUALS, countryCode),
    getExplorationFilter("media_source", FilterOperator.EQUALS, mediaSource),
    getExplorationFilter("device_model", FilterOperator.EQUALS, deviceModel),
    getExplorationFilter("device_category", FilterOperator.EQUALS, deviceCategory),
    getExplorationFilter("first_app_version", FilterOperator.EQUALS, firstAppVersion),
    getExplorationFilter("last_app_version", FilterOperator.EQUALS, lastAppVersion),
  ].filter((f) => !!f);

  const explorationFilters = [...generalFilters, ...invalidUserFilters, ...extraFilters];
  const explorationOptions = {
    cohort_date: cohortDate || "",
    max_day_diff: maxDayDiff !== undefined ? (maxDayDiff as number) : 99,
  };

  const getExpAliasById = (expId: string) => {
    const expGroup = variants_options?.find((item: any) => item?.value === expId);
    return expGroup?.label ?? expId;
  };

  return {
    productCode,
    abaId,
    variants,
    variants_options,
    installDate,
    platforms,
    amaAppIds,
    cohortDate: cohortDate ?? COHORT_DATE.INSTALL_DATE,
    maxDayDiff: maxDayDiff !== undefined ? (maxDayDiff as number) : 99,
    onboardingDate,
    countryCode,
    mediaSource,
    deviceModel,
    deviceCategory,
    firstAppVersion,
    lastAppVersion,
    generalFilters,
    extraFilters,
    invalidUserFilters,
    explorationFilters,
    explorationOptions,
    getExpAliasById,
  };
};
