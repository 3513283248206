/* eslint-disable react-hooks/exhaustive-deps */
import { useCreate, useNavigation, useParsed, useUpdate, useWarnAboutChange } from "@refinedev/core";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { getABTestLocation } from "@src/util/ab-testing/get-ab-test-location";
import { getUserInfo } from "@src/util/get-user-info";
import { Button, Form, Input, Modal, Radio } from "antd";
import React from "react";
import { FilterFormFieldsEnum } from "../enum";
import { shared } from "@src/util/shared";
import { INIT_CONFIG_SHARED_KEY } from "../edit";
import { useCheckEnoughRequiredField } from "@src/hooks/ab-testing/use-check-enough-required-field";
import { ABDuplicateConfigButton } from "./ab-duplicate-config-button";
import { useUpdateCacheInitAbConfig } from "@src/hooks/ab-testing/use-update-cache-init-ab-config";
import { CUSTOM_EVENT_NAME } from "@src/constant/ab-testing/custom-event-name.enum";
import { ABShareConfig } from "./ab-share-config";
import { useAbSaveConfig } from "@src/hooks/ab-testing/save/use-ab-save-config";
import { PlusCircleOutlined, SaveOutlined } from "@ant-design/icons";

const TitleModal = FilterFormFieldsEnum.TITLE + "AsNew";
const DescriptionModal = FilterFormFieldsEnum.DESCRIPTION + "AsNew";
const ModeSave = "save";
const ModeSaveAsNew = "save_as_new";

export const ABSaveConfigComponent: React.FC = () => {
  const { replace } = useNavigation();
  const [, setForceRender] = React.useState(0);
  const { isCreateMode, isEditMode } = getABTestLocation();
  const userInfo = getUserInfo();
  const currentAbConfig = shared.get(INIT_CONFIG_SHARED_KEY);
  const isOwnerOfConfig = currentAbConfig?.owner === userInfo?.email;

  const defaultMode = isCreateMode ? ModeSaveAsNew : isOwnerOfConfig ? ModeSave : ModeSaveAsNew;

  const [getConfigLoading, setGetConfigLoading] = React.useState(true);
  const [openModalSave, setOpenModalSave] = React.useState(false);
  const [optionMode, setOptionMode] = React.useState(defaultMode);

  const timeoutId = React.useRef<any>(null);

  const { setWarnWhen } = useWarnAboutChange();

  const { getCurrentConfig, filterForm } = useABContext();
  const { cacheAbConfig } = useUpdateCacheInitAbConfig();
  const params = useParsed();

  const { enoughFieldsRequired } = useCheckEnoughRequiredField(filterForm);

  const { mutateAsync: createAbTestConfig, isLoading: isLoadingCreateAbTestConfig } = useCreate();
  const { mutateAsync: updateAbTestConfig, isLoading: isLoadingUpdateAbTestConfig } = useUpdate();

  const handleCreateNewConfig = async (payload: any, productCode: string, isSaveAsNew?: boolean) => {
    const newAbTestConfig = await createAbTestConfig({
      resource: "explorations",
      values: payload,
      dataProviderName: "nestjsx",
      meta: {
        headers: {
          "product-code": productCode,
        },
      },
    });
    if (isSaveAsNew) {
      if (isOwnerOfConfig) {
        window.open(`${window.location.origin}/explorations/ab-test/${newAbTestConfig?.data?.id}`, "_blank");
        return;
      }
      replace(`/explorations/ab-test/${newAbTestConfig?.data?.id}`);
      // window.location.href = `${window.location.origin}/explorations/ab-test/${newAbTestConfig?.data?.id}`;
      return;
    }
    if (newAbTestConfig?.data?.id) {
      replace(`/explorations/ab-test/${newAbTestConfig?.data?.id}`);
      // window.location.href = `${window.location.origin}/explorations/ab-test/${newAbTestConfig?.data?.id}`;
    }
  };

  const { isConfigChanging } = useAbSaveConfig();

  React.useEffect(() => {
    setOptionMode(defaultMode);
  }, [defaultMode]);

  React.useEffect(() => {
    setWarnWhen(enoughFieldsRequired && isConfigChanging);
    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
    };
  }, [isConfigChanging, enoughFieldsRequired, isCreateMode]);

  React.useEffect(() => {
    window.addEventListener(CUSTOM_EVENT_NAME.GET_AB_TEST_LOADING, (event: any) => {
      setGetConfigLoading(!!event.detail.loading);
    });
    return () => {
      window.removeEventListener(CUSTOM_EVENT_NAME.GET_AB_TEST_LOADING, () => {
        return;
      });
    };
  }, []);
  const isDisabled = (isCreateMode ? true : isOwnerOfConfig) && (!enoughFieldsRequired || !isConfigChanging);

  const handleClick = async (isPreventNotification?: boolean, isSaveNew?: boolean) => {
    if (isDisabled) return;
    if (!isSaveNew || isCreateMode) {
      setWarnWhen(false);
    }
    const applyFilterEvent = new CustomEvent(CUSTOM_EVENT_NAME.APPLY_FILTER);
    window.dispatchEvent(applyFilterEvent);

    timeoutId.current = setTimeout(async () => {
      await handleFinish(isPreventNotification);
    }, 200);
  };
  const handleFinish = async (isPreventNotification?: boolean) => {
    const _filterForm = getCurrentConfig()?.filterForm;
    const _chartForm = getCurrentConfig()?.chartForm;
    const isSaveAsNew = openModalSave === true && optionMode === ModeSaveAsNew;

    const _title = isSaveAsNew
      ? filterForm.getFieldValue(TitleModal)
      : filterForm.getFieldValue(FilterFormFieldsEnum.TITLE);
    const _description = isSaveAsNew
      ? filterForm.getFieldValue(DescriptionModal)
      : filterForm.getFieldValue(FilterFormFieldsEnum.DESCRIPTION);

    const productCode = _filterForm?.product_code;
    if (!productCode) {
      return;
    }
    const payload = {
      generalConfig: _filterForm,
      chartConfig: _chartForm,
    };
    const values = {
      title: _title,
      description: _description,
      owner: userInfo?.email,
      config: payload,
      type: "AB-Test",
      product_code: productCode,
    };
    if (isCreateMode) {
      handleCreateNewConfig(values, productCode);
      return;
    }
    if (isSaveAsNew) {
      handleCreateNewConfig(values, productCode, true);
      return;
    }
    if (isEditMode && params?.id) {
      if (isOwnerOfConfig) {
        const configSaved = await updateAbTestConfig({
          id: params?.id,
          resource: "explorations",
          values: values,
          dataProviderName: "nestjsx",
          successNotification: isPreventNotification
            ? false
            : () => {
                return {
                  type: "success",
                  message: "Successfully saved",
                  description: "Your changes have been saved",
                };
              },
          meta: {
            headers: {
              "product-code": productCode,
            },
          },
        });

        cacheAbConfig(configSaved.data);
        setForceRender((prev) => prev + 1);
        return;
      }
      handleCreateNewConfig(values, productCode);
    }
  };
  return (
    <div className="ab-save-group flex justify-between items-center">
      {isEditMode && isOwnerOfConfig && (
        <div className="flex items-center">
          <ABShareConfig hasConfigChanged={isConfigChanging} handleClick={handleClick} />
          <ABDuplicateConfigButton hasConfigChanged={isConfigChanging} handleClick={handleClick} />
        </div>
      )}

      {(isCreateMode || !getConfigLoading) && (
        <Button
          icon={isCreateMode ? <PlusCircleOutlined /> : <SaveOutlined />}
          className="ml-4"
          type="primary"
          onClick={() => {
            setOpenModalSave(true);
            setTimeout(() => {
              filterForm.setFieldValue(TitleModal, filterForm.getFieldValue(FilterFormFieldsEnum.TITLE));
              filterForm.setFieldValue(DescriptionModal, filterForm.getFieldValue(FilterFormFieldsEnum.DESCRIPTION));
            }, 100);
          }}
          disabled={isDisabled}
          loading={isLoadingCreateAbTestConfig || isLoadingUpdateAbTestConfig}
        >
          {isOwnerOfConfig ? "Save" : "Create new"}
        </Button>
      )}
      <Modal
        open={openModalSave}
        title={isCreateMode ? "Create new exploration" : "Save exploration"}
        centered
        onOk={async () => {
          await handleClick(false, optionMode === ModeSaveAsNew);
          setOpenModalSave(false);
          setOptionMode(defaultMode);
        }}
        onCancel={() => {
          setOpenModalSave(false);
          setOptionMode(defaultMode);
        }}
      >
        <div className="border-0 border-y-[1px] border-gray-200 border-solid py-4">
          {isEditMode && (
            <div style={{ display: isOwnerOfConfig ? "block" : "none" }}>
              <Radio.Group
                className="flex flex-col"
                value={optionMode}
                onChange={(e) => {
                  const _v = e.target.value;
                  setOptionMode(_v);
                  if (_v === ModeSaveAsNew) {
                    filterForm.setFieldValue(
                      TitleModal,
                      filterForm.getFieldValue(FilterFormFieldsEnum.TITLE) + "  (copy)",
                    );
                    filterForm.setFieldValue(
                      DescriptionModal,
                      filterForm.getFieldValue(FilterFormFieldsEnum.DESCRIPTION),
                    );
                  }
                }}
              >
                <Radio value={ModeSave}>Update this exploration</Radio>
                <Radio value={ModeSaveAsNew}>Save as new exploration</Radio>
              </Radio.Group>
            </div>
          )}
          <div
            style={{
              display: optionMode === ModeSaveAsNew ? "block" : "none",
            }}
            className="mt-4"
          >
            <Form.Item
              label={<span className="w-[80px] flex">Title</span>}
              name={TitleModal}
              required
              rules={[
                {
                  required: true,
                  message: "Please input title!",
                  validator(rule, value, callback) {
                    if (value?.trim()?.length === 0) {
                      callback("Please input title!");
                    } else {
                      callback();
                    }
                  },
                },
              ]}
            >
              <Input placeholder="title" />
            </Form.Item>
            <Form.Item label={<span className="w-[88px] flex">Description</span>} name={DescriptionModal}>
              <Input.TextArea placeholder="description" />
            </Form.Item>
          </div>
        </div>
      </Modal>
    </div>
  );
};
