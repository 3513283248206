import { Button, Input } from "antd";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useParsed } from "@refinedev/core";

function ShareLink({ customLink }: { customLink?: string }) {
  const [copied, setCopied] = React.useState(false);
  const { id } = useParsed();
  const link = customLink ?? `${window.location.origin}/explorations/firebase/share/${id}`;

  function copyLink() {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }
  return (
    <>
      <div className="flex items-center  w-[400px]">
        <Input
          disabled
          defaultValue={link}
          className="pointer-events-none"
          onFocus={(event) => event.target.select()}
        />
        <CopyToClipboard text={link} onCopy={copyLink}>
          <Button type="text" size="small" className="text-[#1890FF] ml-2">
            {copied ? "Copied" : "Copy"}
          </Button>
        </CopyToClipboard>
      </div>
    </>
  );
}

export default ShareLink;
