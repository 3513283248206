import React from "react";
import { Button, Result } from "antd";
import { useNavigation } from "@refinedev/core";

const Error404: React.FC = () => {
  const { replace } = useNavigation();

  return (
    <div className="flex justify-center items-center h-full">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => replace("/")}>
            Go back
          </Button>
        }
      />
    </div>
  );
};

export default Error404;
