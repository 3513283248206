import { ImageField, useTable } from "@refinedev/antd";
import { BaseRecord, CrudFilter, CrudOperators, useNavigation } from "@refinedev/core";
import { useAppContext } from "@src/contexts/app-context";
import { Form, Table, Tag, Tooltip, Typography } from "antd";
import React from "react";
import { dayjsBrowserTimezone } from "@src/util/dayjs-browser-timezone";
import { FormInstance } from "antd/lib/form";
import { ColumnType } from "antd/lib/table";
import { useSearchColumn } from "@src/hooks/table/use-search-column";
import { TitleColumn } from "./component/title-column";
import { ActionsColumn } from "./component/actions-column";
import { EnumRole } from "@src/types/role";
import { useMe } from "@src/hooks/use-me";
import { ExplorationType } from "@src/pages/ab-test-explore/enum/exploration-types.enum";

export const ExploreList: React.FC<{ form: FormInstance<any> }> = ({ form }) => {
  const { push } = useNavigation();
  const { isProductLoading, products } = useAppContext();
  const { roles, isSuperAdmin } = useMe();
  const formLoading = Form.useWatch("form_loading", form);

  const { tableProps, tableQueryResult, setFilters } = useTable({
    resource: "explorations",
    pagination: {
      pageSize: 10,
    },
    queryOptions: {
      enabled: !isProductLoading,
    },
    syncWithLocation: false,
    dataProviderName: "nestjsx",
    meta: {
      join: [
        {
          field: "product",
          select: ["icon", "name", "code"],
        },
      ],
    },
    sorters: {
      initial: [
        {
          field: "updated_at",
          order: "desc",
        },
      ],
    },
  });

  const filterOptionsProduct = React.useMemo(() => {
    return (
      products?.map((item: any) => {
        return {
          text: `${item.name} - ${item.code}`,
          value: item.name,
        };
      }) ?? []
    );
  }, [products]);

  const { getColumnSearchProps } = useSearchColumn();

  const dataSource = tableQueryResult.data?.data;
  const columns: ColumnType<any>[] = React.useMemo(() => {
    return [
      {
        title: "Name",
        dataIndex: "title",
        key: "title",
        filterSearch: true,
        fixed: "left",
        sorter: true,
        ...getColumnSearchProps("title"),
        render: (value: string, record: any) => {
          return <TitleColumn value={value} record={record} />;
        },
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        sorter: true,
        filters: [
          {
            text: "AB Test",
            value: ExplorationType.AB_TEST,
          },
          {
            text: "Firebase",
            value: ExplorationType.FIREBASE,
          },
        ],
        render: (value: string) => {
          if (value === ExplorationType.AB_TEST) {
            return <Tag color="cyan">AB Test</Tag>;
          }
          return <Tag color="blue">{value}</Tag>;
        },
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        width: "20%",
        render: (value: string) => {
          return (
            <Tooltip destroyTooltipOnHide title={value} className="w-[200px]" placement="topLeft">
              <Typography.Paragraph
                ellipsis={{
                  rows: 2,
                  expandable: false,
                }}
              >
                {value || "-"}
              </Typography.Paragraph>
            </Tooltip>
          );
        },
      },
      {
        title: "Product",
        dataIndex: ["product"],
        key: ".product.name",
        sorter: true,
        filters: filterOptionsProduct,
        render: (product: any, record: any) => {
          return (
            <div className="flex items-center space-x-2">
              <ImageField value={product?.icon} width={25} height={25} preview={false} />
              <span>
                {product?.name} - {product?.code}
              </span>
            </div>
          );
        },
      },
      {
        title: "Created At",
        dataIndex: "created_at",
        key: "created_at",
        sorter: true,
        render: (value: number) => {
          return <span>{dayjsBrowserTimezone({ value: value })}</span>;
        },
      },
      {
        title: "Updated At",
        dataIndex: "updated_at",
        key: "updated_at",
        sorter: true,
        render: (value: number) => {
          return <span>{dayjsBrowserTimezone({ value: value })}</span>;
        },
      },
      {
        title: "Actions",
        dataIndex: "actions",
        fixed: "right",
        width: "150px",
        render: (_: any, record: BaseRecord) => {
          return <ActionsColumn record={record} form={form} refetch={tableQueryResult.refetch} />;
        },
      },
    ] as ColumnType<any>[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource, filterOptionsProduct]);
  return (
    <Table
      {...tableProps}
      loading={isProductLoading || tableQueryResult.isFetching || !!formLoading}
      rowKey={"id"}
      onRow={(record) => {
        const myRole = roles.find((role: any) => role.productCode === record.product.code);
        const isProductViewer = myRole?.role === EnumRole.ProductViewer;
        const show = (isProductViewer || !myRole) && !isSuperAdmin;
        let path = `/explorations/firebase/${show ? "show" : "edit"}/${record.id}`;
        if (record?.type === ExplorationType.AB_TEST) {
          path = `/explorations/ab-test/${record.id}`;
        }
        return {
          onClick: () => {
            push(path);
          },
        };
      }}
      columns={columns}
      scroll={{ x: "max-content", y: "calc(100vh - 150px)" }}
      dataSource={dataSource}
      onChange={(pagination, filters, sorter, extra) => {
        const filterCrud: CrudFilter[] = [];
        Object.keys(filters)
          .filter((key) => !!filters[key])
          ?.forEach((key) => {
            let operator: Exclude<CrudOperators, "or" | "and"> = "in";
            if (key === "title") {
              operator = "containss";
            }
            filterCrud.push({
              field: key,
              operator: operator,
              value: filters[key],
            });
          });

        setFilters(filterCrud, "replace");
        return tableProps?.onChange?.(pagination, {}, sorter, extra);
      }}
      pagination={{
        ...tableProps?.pagination,
        pageSizeOptions: [5, 10, 20, 100],
        showSizeChanger: true,
      }}
    />
  );
};
